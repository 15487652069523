import React from 'react'
import { StyledHeaderTitle } from './styled'

export default props => {
    return (
        <StyledHeaderTitle 
            className={props.className}
            color={props.color}>
            {props.text}
        </StyledHeaderTitle>)
}