import styled from 'styled-components'

export const SearchGroup = styled.div`
    background-color: white;
    border-bottom: .0625rem solid ${props => props.statusColor};
    input, textarea {
        background-color: ${props => props.bg? props.bg : 'transparent'};
    }
    
    input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
        color: ${props => props.statusColor};
        background-color: ${props => props.bg? props.bg : 'transparent'};
    }
`

export const InvalidFeedback = styled.p`
    color: ${props => props.theme.color.secondary};
    font-size: 0.8rem;
    
`

export const StyledInput = styled.input`
    background-color: white;
    border: .05rem solid ${props => props.statusColor};
    border-radius: 25px;
    flex-grow: 1;
    padding: 0.5rem 1.5rem;
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active  {
        // outline: 0;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    
`

export const StyledDiv = styled.div`
    
    [class$="-control"]:hover {
        border: solid .05rem ${props => props.theme.color.secondary};
    }
`

export const StyledTextArea = styled.textarea`
    background-color: white;
    border: .05rem solid ${props => props.statusColor};
    border-radius: 25px;
    flex-grow: 1;
    outline: none;
    padding: 0.5rem 1.5rem;
`

export const InputGroup = styled.div`
    background-color: white;
    
    border-radius: 25px;
    font-size: ${props => props.theme.contact.input_font_size}rem;
    
    
    input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
        color: ${props => props.theme.color.placeholder};
        
    }
`