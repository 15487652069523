import styled from 'styled-components'
import MapBackground from 'assets/map_background.jpg'

export const LogoImage = styled.img`
    height: 6.25rem;
    width: 6.25rem;
`

export const NavbarContainer = styled.div`
    height: ${props => props.theme.height_porcentage_mex.navbar}vh
`

export const LegendContainer = styled.div`
    height: ${props => props.theme.height_porcentage_mex.legend}vh
`

export const FooterContainer = styled.div`
    height: ${props => props.theme.height_porcentage_mex.footer}vh
`

export const MapContainer = styled.div`
    background-image: url(${MapBackground});
    background-size: cover;
    background-repeat: no-repeat;
    height: ${props => props.theme.height_porcentage_mex.map}vh;
    padding: 1rem;
`

export const MapGridContainer = styled.div`
    display:grid;
    grid-template-columns: 5% 20% 39.3% 29.7% 6%;
    grid-template-rows: 5% 20% 15% 50% 10%;
    height: 100%;
    max-height: 100%;
    min-width: 2100px;
    @media (max-height: 1800px){
        min-width: 1620px;
    }
    @media (max-height: 1520px){
        min-width: 1368px;
    }

    @media (max-height: 1420px) and (min-width: 1278px){
        min-width: 1278px;
    }
    @media (min-height: 1321px) and (max-height: 1420px) and (max-width: 1277px){
        min-width: 1024px;
        grid-template-columns: 5% 10% 30% 54%;
        grid-template-rows: 5% 20% 25% 40% 10%;
    }

    @media (max-height: 1320px) and (min-width: 1118px){
        min-width: 1188px;
    }
    @media (min-height: 1221px) and (max-height: 1320px) and (max-width: 1117px){
        min-width: 928px;
        grid-template-columns: 1% 63.3% 34.7% 1%;
    }

    @media (max-height: 1220px) and (min-width: 1098px){
        min-width: 1098px;
    }
    @media (min-height: 1121px) and (max-height: 1220px) and (max-width: 1097px){
        min-width: 928px;
        grid-template-columns: 6% 57.3% 34.7% 1%;
    }

    @media (max-height: 1120px) and (min-width: 1008px){
        min-width: 1008px;
    }
    @media (max-height: 1120px) and (max-width: 1007px){
        min-width: 828px;
    }

    @media (max-height: 1020px) and (min-width: 920px){
        min-width: 918px;
    }
    @media (max-height: 1020px) and (max-width: 919px){
        min-width: 828px;
    }

    @media (max-height: 920px) and (min-width: 769px){
        min-width: 828px;
    }
    @media (max-height: 820px) and (min-width: 769px){
        min-width: 738px;
    }
    @media (max-height: 720px) and (min-width: 769px){
        min-width: 660px;
    }
`

export const SVGMapContainer = styled.div`
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 6;
    width: 100%;
    margin-top: -2.5rem;
`

export const TitleContainer = styled.div`
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: end;
`

export const IndicatorsContainer = styled.div`
    align-self: start;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 6;
    text-align: start;
`

export const ReturnContainer = styled.div`
    align-self: start;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    text-align: start;
`

export const MoreDownContainer = styled.div`
    height: calc(${props => props.theme.height_porcentage_mex.see_more})
`