import React from 'react'
import { FooterContainer, 
    // ColorLegendCircle, 
    StyledSpan,
    GradientBarMex
 } from './styled'
// import { THEME } from 'constants/theme'
import { map_legend } from 'constants/dictionary'

export default props => {
    return(
        <FooterContainer className='row no-gutters justify-content-center'>
            <div 
                className='d-flex align-items-center justify-content-center col-12 col-lg-8 pt-1 pt-md-0'>
                <div className='d-flex justify-content-right px-2'>
                    <StyledSpan className='text-right'>{map_legend.allowed}</StyledSpan>
                </div>
                <div className='w-25 d-flex justify-content-around mx-2 mx-md-4'>
                    {/* <ColorLegendCircle color={THEME.color.abortion_level_1} className='rounded-circle  ml-1 ml-md-0'/>
                    <ColorLegendCircle color={THEME.color.abortion_level_2} className='rounded-circle  ml-1 ml-md-0'/>
                    <ColorLegendCircle color={THEME.color.abortion_level_3} className='rounded-circle  ml-1 ml-md-0'/>
                    <ColorLegendCircle color={THEME.color.abortion_level_4} className='rounded-circle  ml-1 ml-md-0'/> */}
                    <GradientBarMex/>
                </div>
                
                <div className='d-flex justify-content-center px-2'>
                    <StyledSpan>{map_legend.not_allowed}</StyledSpan>
                </div>
            </div>
        </FooterContainer>
    )
}