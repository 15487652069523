import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from './styled'
import ExpandIcon from 'components/atoms/images/expand_sidebar'
import ContractIcon from 'components/atoms/images/contract_sidebar'
import { setMobileSidebarStatus } from 'actions/shared'
import { logFirstTimeView } from 'actions/auth'

export default function MobileSidebarButton(){
    const dispatch = useDispatch()
    const isOpen = useSelector(({ shared }) => shared.mobile_sidebar_opened)
    const first_time = useSelector(({ auth }) => auth.first_time)

    const onBurguerClick = () => {
        dispatch(
            setMobileSidebarStatus(!isOpen)
        )
        if(first_time)
            dispatch(logFirstTimeView())
    }

    return(
            <Container
                className='d-flex justify-content-center align-items-center'
                onClick={() => onBurguerClick()}>
                {
                    isOpen ?
                        <ContractIcon/>
                    :
                        <ExpandIcon/>
                }
            </Container>
    )
}