import React from 'react'
import MediaQuery from 'react-responsive'
import { StyledIndicators } from './styled'
import HowToSVG from 'assets/how_to_use.svg'
import HowToMobileSVG from 'assets/how_to_use_mobile.svg'

export default (props) => {
  return (
    <>
      <MediaQuery maxDeviceWidth={767}>
        <StyledIndicators src={HowToMobileSVG} className='my-2' />
      </MediaQuery>
      <MediaQuery minDeviceWidth={768}>
        <StyledIndicators src={HowToSVG} />
      </MediaQuery>
    </>
  )
}
