import { 
    CONTACT
} from 'constants/index'

const INITIAL_STATE = { 
    send_email_status: false,
    country_options: []
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case CONTACT.SEND_EMAIL_STATUS:
            return { ...state, send_email_status: action.payload }
        case CONTACT.GET_COUNTRIES_OPTIONS:
            return { ...state, country_options: action.payload }
        default:
            return { ...state }
    }
}
