import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import SearchSelect from 'components/atoms/inputs/contact_search'
import Input from 'components/atoms/inputs/simple'
import Textarea from 'components/atoms/inputs/textarea'
import Button from 'components/atoms/buttons/small'
import { buttons, contact_view } from 'constants/dictionary'
import { THEME } from 'constants/theme'
import { FORM } from 'constants/index'
import BodyText from 'components/atoms/text/body'
import Subtitle from 'components/atoms/text/header_title'
import EmailSentImg from 'components/atoms/images/contact_sent'
import Alert from 'components/molecules/alert/contact'
import { sendContactEmail, changeSendContactEmailStatus } from 'actions/contact'

class ContactForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			email_sent: false
		}
		this.onSubmit = this.onSubmit.bind(this)
	}

	componentWillUnmount() {
		this.props.changeSendContactEmailStatus(false)
	}

	onSubmit(values) {
		const email = {
			country: values.country_select.label,
			email: values.email,
			message: values.message
		}
		this.props.sendContactEmail(email, (result) => {
			if (result) {
				this.setState({
					email_sent: true
				})
				this.props.changeSendContactEmailStatus(true)
			}
		})
	}

	render() {
		const { handleSubmit } = this.props

		return this.state.email_sent ? (
			<div className='d-flex flex-column pt-4 pt-lg-5'>
				<div className='text-center'>
					<EmailSentImg />
				</div>
				<div className='text-center my-2'>
					<Subtitle color={THEME.color.primary} text={contact_view.form.success.title.es} />
				</div>
				<div className='text-center py-4'>
					<BodyText>{contact_view.form.success.subtitle.es}</BodyText>
				</div>
			</div>
		) : (
			<>
				<Alert />
				<form onSubmit={handleSubmit(this.onSubmit)}>
					<div className='d-flex flex-column pt-2 pt-md-4 pt-lg-5'>
						<div className='my-2'>
							<Field component={SearchSelect} id='id_country_select' isMultiple={false} name='country_select' options={this.props.options} placeholder={contact_view.form.country.es} />
						</div>
						<div className='my-3'>
							<Field component={Input} name='email' placeholder={contact_view.form.email.es} />
						</div>
						<div className='my-3'>
							<Field component={Textarea} name='message' placeholder={contact_view.form.message.es} />
						</div>
						<div className='text-center my-3'>
							<Button bgColor={THEME.color.secondary} type='submit' text={buttons.send.es} />
						</div>
					</div>
				</form>
				<div className='my-4'>
					<BodyText>
						{contact_view.alternative.es}
						<br />
						<a href='mailto:ipaslac@ipas.org'>ipaslac@ipas.org</a>
					</BodyText>
				</div>
			</>
		)
	}
}

function validate(values) {
	const errors = {}

	if (!values.country_select) errors.country_select = 'Por favor, selecciona una opción.'

	if (!values.email) errors.email = 'Por favor, ingresa un correo electrónico.'
	else if (!FORM.EMAIL_REGEX.test(values.email)) errors.email = 'Por favor, ingresa un correo electrónico válido.'

	if (!values.message) errors.message = 'Por favor, ingresa un mensaje.'

	return errors
}

ContactForm = reduxForm({
	form: 'contact_form',
	validate
})(ContactForm)

ContactForm = connect(null, { sendContactEmail, changeSendContactEmailStatus })(ContactForm)

export default ContactForm
