import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchAllZonesOfMexico } from 'actions/map'
import QueriesRules from './queries'
import ReactGA from 'react-ga'

ReactGA.initialize(process.env.REACT_APP_PRD_ANALYTICS_TRACKING_ID)

class MexicoIndex extends Component{
    
    componentDidMount(){

        // const viewportmeta = document.querySelector('meta[name=viewport]')
        // viewportmeta.setAttribute('content', "width=device-width, initial-scale=0")
        // viewportmeta.setAttribute('content', "width=device-width, initial-scale=1")

        ReactGA.pageview('/mapa-mexico')
        this.props.fetchAllZonesOfMexico()
    }

    componentWillUnmount(){
        window.location.assign(this.props.history.location.pathname)
    }

    render(){
        return(
            <QueriesRules/>
        )
    }
}

MexicoIndex = connect(
    null,
    { fetchAllZonesOfMexico }
)(MexicoIndex)

export default withRouter(MexicoIndex)