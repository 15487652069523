import React from 'react'
import { StyledImageModal, StyledModalHeader } from './styled'
import { ModalBody } from 'reactstrap'
import CloseModal from 'components/atoms/images/close_modal'

const ImageModal = (props) => {
    const {
        image,
        modal,
        toggle
    } = props

    return (
        <StyledImageModal isOpen={modal} toggle={toggle} backdrop={'static'} centered={true}>
            <StyledModalHeader onClick={toggle}>
                <div className='d-flex justify-content-end'>
                    <CloseModal/>
                </div>
            </StyledModalHeader>
            <ModalBody className='p-2'>
                {image}
            </ModalBody>
        </StyledImageModal>
    )
}

export default ImageModal
