import React from 'react'
import Select from 'react-select'
import { THEME } from 'constants/theme'
import { StyledDiv, InvalidFeedback } from './styled'

class SelectSearchInput extends React.Component {
  render(){
    const { meta: { error, touched, active } } = this.props
    const isInvalid = touched && error
    const statusColor = touched && error
                        ? `solid 0.05rem ${THEME.color.secondary}`
                        : active
                        ? `solid 0.05rem ${THEME.color.secondary}`
                        : 'none'
    const colourStyles = {
      control: styles => {
        return {
          ...styles,
          backgroundColor: 'white',
          border: statusColor,
          borderRadius: 25,
          boxShadow: "none",
          fontSize: `${THEME.contact.input_font_size}rem`,
          marginLeft: 'auto',
          outline: 'none!important',
          paddingLeft: '1rem',
          paddingBottom: '0rem'
          
        }
      },
      indicatorSeparator: styles => {
        return {
          display: 'none'
        }
      },
      placeholder: styles => {
        return {
          ...styles,
          color: `${THEME.color.placeholder}`,
          fontSize: `${THEME.contact.input_font_size}rem`,
        }
      },
      option: (styles, { isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused
            ? THEME.color.secondary
            : isSelected
            ? THEME.color.secondary
            : null,
          color: isFocused || isSelected ? 'white' : 'black',
          fontSize: `${THEME.contact.input_font_size}rem`,
        }
      }
    }

    return(
      <StyledDiv className='form-group mb-0'>
        <Select 
            blurInputOnSelect={false}
            className='w-100'
            closeMenuOnSelect={this.props.isMultiple ? false : true}
            isMulti={this.props.isMultiple}
            name={this.props.input.name}
            noOptionsMessage={() => 'Ningún resultado'}
            onBlurResetsInput={false}
            onChange={value => {
                this.props.input.onChange(value)
            }}
            onCloseResetsInput={false}
            onFocus={this.props.input.onFocus}
            options={this.props.options}
            placeholder={this.props.placeholder}
            styles={colourStyles}
            value={this.props.input.value}
        />
        <InvalidFeedback className='mx-3'>
            { isInvalid ? error : ''}
        </InvalidFeedback>
      </StyledDiv>
    )
  }
}

export default SelectSearchInput