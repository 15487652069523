import styled from 'styled-components'
import LiImage from 'assets/list_item_image.svg'
import LiResponsiveImage from 'assets/list_item_responsive_image.svg'

export const TextContainer = styled.div`
    p, span {
        color: ${props => {
            if(props.hover) return 'lightblue'
            else return props.color ? props.color : props.theme.color.label
        }}!important;
        font-weight: normal;
        font-size: 0.8rem;
        line-height: 1.2rem;
        @media (max-width: 779px){
            font-size: 0.7rem;
            line-height: 1.1rem;
        }
    }

    strong {
        color: ${props => {
            if(props.hover) return 'lightblue'
            else return props.color ? props.color : props.theme.color.primary
        }}!important;
        font-family: 'Metropolis Bold';
    }

    a {
        color: ${props => props.theme.color.link}!important;
        text-decoration: underline;
    }

    ul {
        list-style-image: url(${LiImage});
        @media (max-width: 779px){
            list-style-image: url(${LiResponsiveImage});
        }
    }

`