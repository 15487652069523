import React from 'react'
import { StyledImportantPoint } from './styled'

export default props => {
    return(
        <StyledImportantPoint 
            className={props.className}
            color={props.color}
            hover={props.hover} 
            inactive={props.inactive}>
            {props.text}
        </StyledImportantPoint>
    )
}