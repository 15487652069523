import React from 'react'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import parse from 'html-react-parser'
import { show_region } from 'constants/dictionary'
import { TextContainer } from './styled'
import Subtitle from 'components/atoms/text/subtitle'
import Body from 'components/atoms/text/body'
import Strong from 'components/atoms/text/strong'
import References from 'components/organisms/references'
import { THEME } from 'constants/theme'

export default (props) => {
	if (!props.allowed || isEmpty(props.allowed)) return show_region.no_info
	else
		return (
			<div>
				<TextContainer>
					<Subtitle text={show_region.titles.allowed[props.language].replace(':region_name', props.name)} />
					<div className='my-4'>{parse(props.allowed.description)}</div>

					<ul>{renderCausals(props.allowed)}</ul>

					{props.needed && !isEmpty(props.needed) && parse(props.needed.description)}
				</TextContainer>
				{props.allowed.penal_codes && props.allowed.penal_codes.length > 0 && <References language={props.language} references={props.allowed.penal_codes} />}
			</div>
		)
}

function renderCausals(allowed) {
	if (!allowed.causals || allowed.causals.length <= 0) return ''
	else
		return map(allowed.causals, (causal, key) => {
			return (
				<li className='my-3' key={key}>
					<Body>
						{causal.title && <Strong color={THEME.color.primary}>{parse(causal.title)}:</Strong>} {causal.description && parse(causal.description)}
					</Body>
				</li>
			)
		})
}
