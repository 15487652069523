import React from 'react'
import Logo from 'components/atoms/images/logo_navbar'
import { Container, LogoContainer } from './styled'

export default props => {
    
        return(
            <Container className='row no-gutters'>
                <div className='col-3 pl-2 pl-md-4'>
                    <LogoContainer className='d-flex align-items-center'>
                        <Logo/>
                    </LogoContainer>
                </div>
            </Container>
        )
}