import styled from 'styled-components'
import MapBackground from 'assets/map_background.jpg'

export const LogoImage = styled.img`
    height: 6.25rem;
    width: 6.25rem;
`

export const NavbarContainer = styled.div`
    height: ${props => props.theme.height_porcentage.navbar}vh
`

export const LegendContainer = styled.div`
    height: ${props => props.theme.height_porcentage.legend}vh
`

export const FooterContainer = styled.div`
    height: ${props => props.theme.height_porcentage.footer}vh
`

export const MapContainer = styled.div`
    background-image: url(${MapBackground});
    background-size: cover;
    background-repeat: no-repeat;
    height: ${props => props.theme.height_porcentage.map}vh;
    padding: 1rem;
`

export const MapGridContainer = styled.div`
    display:grid;
    grid-template-columns: 13% 51.3% 29.7% 6%;
    grid-template-rows: 25.7% 28.9% 33.7% 11.7%;
    height: 100%;
    max-height: 100%;
    min-width: 2100px;
    @media (max-height: 1800px){
        min-width: 1620px;
    }
    @media (max-height: 1520px){
        min-width: 1368px;
    }

    @media (max-height: 1420px) and (min-width: 1278px){
        min-width: 1278px;
    }
    @media (min-height: 1321px) and (max-height: 1420px) and (max-width: 1277px){
        min-width: 1024px;
        grid-template-columns: 1% 63.3% 34.7% 1%;
    }

    @media (max-height: 1320px) and (min-width: 1118px){
        min-width: 1188px;
    }
    @media (min-height: 1221px) and (max-height: 1320px) and (max-width: 1117px){
        min-width: 928px;
        grid-template-columns: 1% 63.3% 34.7% 1%;
    }

    @media (max-height: 1220px) and (min-width: 1098px){
        min-width: 1098px;
    }
    @media (min-height: 1121px) and (max-height: 1220px) and (max-width: 1097px){
        min-width: 1000px;
        grid-template-columns: 6% 57.3% 34.7% 1%;
    }



    @media (min-height: 1025px) and (max-height: 1120px) and (min-width: 1008px){
        min-width: 1008px;
    }
    @media (min-height: 1025px) and (max-height: 1120px) and (max-width: 1007px){
        min-width: 828px;
    }

    @media (max-height: 1024px) and (min-width: 920px){
        min-width: 918px;
    }
    @media (max-height: 1024px) and (max-width: 919px){
        min-width: 828px;
    }

    @media (max-height: 920px) and (min-width: 769px){
        min-width: 828px;
    }
    @media (max-height: 820px) and (min-width: 769px){
        min-width: 738px;
    }
    @media (max-height: 720px) and (max-width: 769px){
        min-width: 660px;
    }


    // Nuevos queries
    @media (min-width: 768px) and (min-height: 640px){
        grid-template-columns: 5% 60% 35% 0%;
        grid-template-rows: 25% 5% 55% 15%;    
        min-width: 700px;
        min-width: 100%;
    }

    @media (min-width: 860px) and (min-height: 640px){
        grid-template-columns: 5% 60% 35% 0%;
        grid-template-rows: 25% 5% 55% 15%;    
        min-width: 700px;
        min-width: 80%;
    }

    @media (min-width: 1024px) and (min-height: 800px){
        grid-template-columns: 5% 60% 33% 2%;
        grid-template-rows: 25% 5% 55% 15%;    
        min-width: 1024px;
        min-width: 90%;
    }

    @media (min-width: 1280px) and (min-height: 800px){
        grid-template-columns: 5% 60% 33% 2%;
        grid-template-rows: 25% 5% 55% 15%;    
        min-width: 1024px;
        min-width: 70%;
    }

    @media (min-width: 1080px) and (min-height: 960px){
        grid-template-columns: 5% 60% 33% 2%;
        grid-template-rows: 25% 5% 55% 15%;    
        min-width: 1024px;
        min-width: 100%;
    }

    @media (min-width: 1400px) and (min-height: 960px){
        grid-template-columns: 5% 60% 33% 2%;
        grid-template-rows: 25% 5% 55% 15%;    
        min-width: 1024px;
        min-width: 80%;
    }

    @media (min-width: 1440px) and (min-height: 1080px){
        grid-template-columns: 5% 60% 35% 0%;
        grid-template-rows: 25% 5% 55% 15%;    
        min-width: 1320px;
        min-width: 85%;
    }
    
    @media (min-width: 2048px) and (min-height: 1200px){
        grid-template-columns: 5% 60% 30% 5%;
        grid-template-rows: 25% 5% 55% 15%;    
        min-width: 1620px;
        min-width: 80%;
    }

    @media (min-width: 3000px) and (min-height: 2000px){
        grid-template-columns: 5% 60% 30% 5%;
        grid-template-rows: 25% 5% 55% 15%;    
        min-width: 2048px;
        min-width: 70%;
    }
    
    @media (max-width: 1024px) and (min-height: 1300px){
        grid-template-columns: 1% 64% 35% 0%;
        grid-template-rows: 25% 5% 55% 15%;    
        min-width: 900px;
        min-width: 100%;
    }

    @media (max-width: 2000px) and (min-height: 3000px){
        grid-template-columns: 5% 25% 25% 0%;
        grid-template-rows: 25% 5% 55% 15%;    
        min-width: 1024px;
        min-width: 100%;
    }
`

export const SVGMapContainer = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 5;
    width: 100%;
`

export const TitleContainer = styled.div`
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    padding-top: 1rem;
    text-align: end;
`

export const IndicatorsContainer = styled.div`
    display: none;
    align-self: start;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: start;
`

export const InfoContainer = styled.div`
    display: none;
    align-self: start;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
    text-align: end;
`
