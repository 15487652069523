import React from 'react'
import { slide as SidebarContent } from 'react-burger-menu'
import SidebarButton from 'components/molecules/sidebar_button'
import SidebarWhatIcon from 'components/atoms/images/sidebar_what'
import SidebarHowIcon from 'components/atoms/images/sidebar_how'
import SidebarWhatInfo from 'components/atoms/images/sidebar_what_info'
import SidebarHowInfo from 'components/atoms/images/sidebar_how_info'
import { Container, RowContent, RowTimeline } from './styled'

export default function Sidebar(props){
    return(
        <SidebarContent
                customBurgerIcon={ false }
                customCrossIcon={ false }
                isOpen={ props.isOpen }
                noOverlay
                width={ '30%' }
            >
            <Container className='d-flex' isOpen={ props.isOpen }>
                <div className='flex-grow-1 py-4'>
                    <RowTimeline className='row'>
                        <div className='col-2'>
                            <div className='corner right-bottom'></div>
                        </div>
                        <div className='col-9 text-center'>
                            <hr/>
                            <SidebarWhatIcon/>
                        </div>
                    </RowTimeline>
                    <RowContent className='row'>
                        <div className='col-2'>
                            <div className='col-2 text-center full-left'></div>
                        </div>
                        
                        <div className='col-8 py-4'>
                            <SidebarWhatInfo/>
                        </div>
                    </RowContent>
                    <RowTimeline className='row'>
                        <div className='col-2'>
                            <div className='corner top-right'></div>
                        </div>
                        <div className='col-8'>
                            <hr/>
                            <SidebarHowIcon/>
                        </div>
                        <div className='col-2'>
                            <div className='corner left-bottom'></div>
                        </div>
                    </RowTimeline>
                    <RowContent className='row'>
                        <div className='col-2'></div>
                        <div className='col-8 py-4'>
                            <SidebarHowInfo/>
                        </div>
                        <div className='col-2'>
                            <div className='col-2 text-center full-right'></div>
                        </div>
                    </RowContent>
                    <RowTimeline className='row'>
                        <div className='col-1'></div>
                        <div className='col-9'>
                            <hr/>
                        </div>
                        <div className='col-2'>
                            <div className='corner top-left'></div>
                        </div>
                    </RowTimeline>
                </div>
                <SidebarButton/>
            </Container>
        </SidebarContent>
    )
}