import React from 'react'
import { InputGroup, StyledInput, InvalidFeedback } from './styled'
import { THEME } from 'constants/theme'

export default field => {
    const { meta: { error, touched, active } } = field
    const isInvalid = touched && error ? true : false
    const statusColor = () => {
        if(touched && error) 
            return THEME.color.secondary
        else if(active) 
            return THEME.color.secondary
        else return 'transparent'
    }

    return(
        <div className='form-group mb-0'>
            <InputGroup 
                className={`d-flex input-group`}
                bg={field.bg}
                statusColor={statusColor()}>
                <StyledInput
                    type={field.type ? field.type : 'text'}
                    name={field.name}
                    placeholder={field.placeholder}
                    invalid={isInvalid}
                    autoComplete={field.autocomplete}
                    maxLength={field.max}
                    disabled={field.disabled}
                    statusColor={statusColor()}
                    {...field.input}
                />
            </InputGroup>
            <InvalidFeedback className='mx-3'>
                { isInvalid ? error : ''}
            </InvalidFeedback>
        </div>
    )
}