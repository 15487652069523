import styled from 'styled-components'

export const ContainerDiv = styled.div`
    height: 100vh;
    margin: 0;
    overflow: hidden;
    position: relative;

    .disable-scrollbars::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
    }
    
    .disable-scrollbars {
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */
    }
`

export const LeftDiv = styled.div`
    bottom: 0;
    left: 0;
    overflow-y: scroll;     
    position: absolute;
    top: 0;
`

export const RightDiv = styled.div`
    bottom: 0;
    overflow-y: scroll;
    position: absolute;
    right: 0;
    top: 0;
`

export const FooterContainer = styled.div`
    height: ${props => props.theme.footer_container}vh;
`