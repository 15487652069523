import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, reset } from 'redux-form'
import SearchSelect from 'components/atoms/inputs/desktop_search'
import SearchIcon from 'components/atoms/images/circle_search_icon'
import { navbar } from 'constants/dictionary'
import { NAVBAR_TYPE } from 'constants/index'
import { FormContainer, SearchContainer } from './styled'

class SearchBar extends React.Component{
    constructor(props){
        super(props)
        this.onSubmit = this.onSubmit.bind(this)

    }

    onSubmit(values){
        if (values.search_select)
            this.props.onSearchSubmit(values.search_select.value)
        else if(values.value)
            this.props.onSearchSubmit(values.value)
        this.props.reset('desktop_search_bar')
    }

    render(){

        const { handleSubmit } = this.props

        return(
            <FormContainer className='bg- d-flex align-items-center justify-content-end'>
            <form 
                className='pr-2'
                onSubmit={handleSubmit(this.onSubmit)}>
                <SearchContainer className='d-flex align-items-center '>
                    <SearchIcon/>
                    <Field 
                        component={SearchSelect}
                        forzeSubmit={value => { this.onSubmit(value) }}
                        id='id_search_select'
                        isMultiple={false}
                        name='search_select'
                        options={this.props.options}
                        placeholder={this.props.type === NAVBAR_TYPE.countries ? navbar.countries.search_placeholder : navbar.zones.search_placeholder}
                    />
                    
                </SearchContainer>
            </form>
            </FormContainer>
        )
    }
}

function validate(values){
    let errors = {}

    if(!values.search_select)
        errors.search_select = ''

    return errors
}

SearchBar = reduxForm({
    form: 'desktop_search_bar',
    validate
})(SearchBar)

SearchBar = connect(
    null,
    { reset }
)(SearchBar)

export default SearchBar