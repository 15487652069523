export let THEME = {
  color: {
    primary: '#532380',
    secondary: '#E6057E',
    tertiary: '#F297D1',
    abortion_level_0: '#ffffff',
    abortion_level_1: '#00A950',
    abortion_level_2: '#52247F',
    abortion_level_2_5: '#8B2180',
    abortion_level_3: '#22BCB9',
    abortion_level_4: '#0069AA',
    placeholder: '#333333',
    label: '#333333',
    title: '#333333',
    label_hover: '#333333',
    upper_footer: '#F8971D',
    sub_footer: '#494949',
    background: '#F6F8FF',
    alert_background: '#E7ECFF',
    alert_font: '#4F4F4F',
    inactive_tab_background: '#F2F2F2',
    active_tab_background: '#F297D1',
    link: '#0069AA',
    error: 'red'
  },
  height_porcentage: {
    navbar: 9,
    map: 67,
    legend: 6,
    footer: 18,
    sidebar: 91
  },
  height_porcentage_mex: {
    navbar: 9,
    map: 80,
    legend: 8,
    see_more: 4,
    footer: 22
  },
  mobile_height_porcentage: {
    navbar: 7.85,
    map: 84.41,
    legend: 7.74
  },
  mobile_height_porcentage_mex: {
    navbar: 8,
    map: 78,
    legend: 8,
    see_more: 6
  },
  help_desktop: {
    border_radius: 16,
    line_weight: 2,
    timeline_height: 40
  },
  desktop_search_height: 1.5,
  desktop_search_button_height: 2, // o sin definir
  desktop_search_icon_size: 1,
  desktop_search_font_size: 0.9,
  desktop_navbar_logo_size: 7,
  desktop_molecule_navbar_height: 4,
  footer_container: 20,
  contact: {
    border_radius: 16,
    line_weight: 5,
    input_font_size: 0.8
  }
}
