import React, { Component } from 'react'
import { connect } from 'react-redux'
import { 
    PageLoaderDiv, 
    // StyledPrimaryH3, 
    // StyledPrimaryH5, 
    StyledSecondaryH5, StyledSecondaryH6 } from './styled'

class PageLoader extends Component{

    render(){
        return(
            <PageLoaderDiv active={this.props.pageLoading} hd={window.screen.width > 768}>
                {/* <StyledPrimaryH3 className='d-none d-md-block'>Estamos procesando tu información.</StyledPrimaryH3> */}
                <StyledSecondaryH5 className='d-none d-md-block'>No cierres la página</StyledSecondaryH5>
                {/* <StyledPrimaryH5 className='d-md-none'>Estamos procesando tu información.</StyledPrimaryH5> */}
                <StyledSecondaryH6 className='d-md-none'>No cierres la página</StyledSecondaryH6>
            </PageLoaderDiv>
        )
    }
}

function mapStateToProps({ shared }){
    let { pageLoading } = shared
    return { pageLoading }
}

export default connect(mapStateToProps, {} )(PageLoader)