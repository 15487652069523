import { 
    AUTH,
    SHARED
} from 'constants/index'

const INITIAL_STATE = { 
    first_time: true,
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case SHARED.CLEAN_CURRENT_DATA:
            state = INITIAL_STATE
            return { ...state }

        case AUTH.LOG_FIRST_TIME_VIEW:
            return {
                ...state,
                first_time: action.payload
            }
        default:
            return { ...state }
    }
}
