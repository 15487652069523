import React, { useEffect, useState } from 'react'
import { StyledLogo } from './styled'
import { firestore } from 'firebase/index'

const HtmlContent = () => {
  const [htmlContent, setHtmlContent] = useState('')

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const doc = await firestore.collection('configuration').doc('logoSuperior').get()
        if (doc.exists) {
          const data = doc.data()
          setHtmlContent(data.url)
        } else {
          console.log('No such document!')
        }
      } catch (error) {
        console.error('Error fetching document: ', error)
      }
    }

    fetchHtmlContent()
  }, [])

  return (
    <a href='/'>
      <StyledLogo src={htmlContent} />
    </a>
  )
}

export default HtmlContent
