import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, reset } from 'redux-form'
import SearchSelect from 'components/atoms/inputs/mobile_search'
import { navbar } from 'constants/dictionary'
import { NAVBAR_TYPE } from 'constants/index'
import { StyledForm, DivShadow } from './styled'

class SearchBar extends React.Component{
    constructor(props){
        super(props)
        this.onSubmit = this.onSubmit.bind(this)

    }

    onSubmit(values){
        if (values.search_select){
            if(this.props.type === NAVBAR_TYPE.countries){
                this.props.onSearchSubmit(values.search_select.value)
            }
            else
                this.props.onSearchSubmit(values.search_select.value)
        }
        else if (values.value){
            if(this.props.type === NAVBAR_TYPE.countries){
                this.props.onSearchSubmit(values.value)
            }
            else
                this.props.onSearchSubmit(values.value)
        }
        this.props.reset('mobile_search_bar')
    }

    render(){

        const { handleSubmit } = this.props

        return(
            <StyledForm
                onSubmit={handleSubmit(this.onSubmit)}>
                    <DivShadow className='d-flex'>
                    <Field 
                        component={SearchSelect}
                        forzeSubmit={value => { this.onSubmit(value) }}
                        id='id_search_select'
                        isMultiple={false}
                        name='search_select'
                        options={this.props.options}
                        placeholder={this.props.type === NAVBAR_TYPE.countries ? navbar.countries.search_placeholder : navbar.zones.search_placeholder}
                    />
                    </DivShadow>
            </StyledForm>
        )
    }
}

function validate(values){
    let errors = {}

    if(!values.search_select)
        errors.search_select = ''

    return errors
}

SearchBar = reduxForm({
    form: 'mobile_search_bar',
    validate
})(SearchBar)

SearchBar = connect(
    null,
    { reset }
)(SearchBar)

export default SearchBar