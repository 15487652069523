import { ALERTS, SHARED } from 'constants/index'

export function closeAlert(){
  return dispatch => {
    dispatch({
      type: ALERTS.CLOSE,
      payload: false
    })
  }
}

export function sendErrorAlert(message){
  return dispatch => {
    dispatch({
      type: ALERTS.SHOW_ERROR_MESSAGE,
      payload: message
    })
  }
}

export function sendSuccessAlert(message){
  return dispatch => {
    dispatch({
      type: ALERTS.SHOW_SUCCESS_MESSAGE,
      payload: message
    })
  }
}

export function selectMenuSection(section){
  return dispatch => {
    dispatch({
      type: SHARED.SELECT_MENU_SECTION,
      payload: section
    })
  }
}

export function setContentTab(tab){
  return dispatch => {
    dispatch({
      type: SHARED.SET_CONTENT_TAB,
      payload: tab
    })
  }
}

export function setSidebarStatus(status){
  return dispatch => {
    dispatch({
      type: SHARED.SET_SIDEBAR_STATUS,
      payload: status
    })
  }
}

export function setMobileSidebarStatus(status){
  return dispatch => {
    dispatch({
      type: SHARED.SET_MOBILE_SIDEBAR_STATUS,
      payload: status
    })
  }
}