import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Navbar from 'components/molecules/navbar/index'
import Header from 'components/molecules/country_header'
import Help from 'components/organisms/help'
import ContentTabs from 'components/organisms/content_tabs'
import Allowed from 'components/organisms/allowed'
import Access from 'components/organisms/access'
import Footer from 'components/molecules/footer'
import * as routes from 'constants/routes'
import { ContainerDiv, LeftDiv, RightDiv, FooterContainer } from './styled'
import { MAP } from 'constants/index'

class DesktopZone extends React.Component {
  constructor(props) {
    super(props)
    this.onReturnClick = this.onReturnClick.bind(this)
  }

  onReturnClick() {
    // if(this.props.current_country.code === 'MEX')
    this.props.history.push(routes.MEXICO_MAP)
  }

  render() {
    // en un futuro para zonas de otros paises se tendrá que guardar idioma en countries_simple_data
    const language = this.props.current_country.language ? this.props.current_country.language.value : 'es'

    return (
      <>
        <Navbar />
        <Header
          zone_code={this.props.current_zone.code}
          country_language={language}
          zone_level={this.props.current_zone.level}
          zone_name={this.props.current_zone.name}
          country_name={this.props.current_country.name}
          onReturnClick={this.onReturnClick}
        />
        <ContainerDiv className='row no-gutters my-4'>
          <LeftDiv className='col-4 p-2 p-md-4 disable-scrollbars'>
            <Help help={this.props.current_zone.help} language={language} />
          </LeftDiv>
          <RightDiv className='col-8 p-2 p-md-4 disable-scrollbars'>
            <ContentTabs language={language} />
            <div className='py-5'>
              {this.props.content_tab_selected === MAP.TABS.allowed ? (
                <Allowed allowed={this.props.current_zone.allowed} language={language} name={this.props.current_zone.name} needed={this.props.current_zone.needed} />
              ) : (
                <Access access={this.props.current_zone.access} language={language} name={this.props.current_zone.name} />
              )}
            </div>
          </RightDiv>
        </ContainerDiv>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </>
    )
  }
}

function mapStateToProps({ map, shared }) {
  const { current_country, current_zone } = map
  const { content_tab_selected } = shared
  return { current_country, current_zone, content_tab_selected }
}

DesktopZone = connect(mapStateToProps, {})(DesktopZone)

export default withRouter(DesktopZone)
