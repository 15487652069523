import React from 'react'
import Subtitle from 'components/atoms/text/subtitle'
import parse from 'html-react-parser'
import Body from 'components/atoms/text/body'
import Strong from 'components/atoms/text/strong'
import { THEME } from 'constants/theme'
import map from 'lodash/map'

export default (props) => {
	console.log('props', props.allowed?.causals)
	return (
		<div className='p-3 p-md-5'>
			<Subtitle className='mt-2 mt-md-0' text='¿Está permitido interrumpir el embarazo en México?' />
			<div className='my-4'>{parse(props?.allowed?.description || '')}</div>
			<ul>{renderCausals(props?.allowed || [])}</ul>
			<div className='my-4'>
				Para tener más información sobre qué supuestos están contemplados en tu estado y las diferentes formas para acceder a servicios de aborto legal y seguros, te invitamos a consultar la
				información que ponemos a tu disposición en este mapa interactivo.
			</div>
		</div>
	)
}

function renderCausals(allowed) {
	if (!allowed.causals || allowed.causals.length <= 0) return ''
	else
		return map(allowed.causals, (causal, key) => {
			return (
				<li className='my-3' key={key}>
					<Body>
						{causal.title && <Strong color={THEME.color.primary}>{parse(causal.title)}:</Strong>} {causal.description && parse(causal.description)}
					</Body>
				</li>
			)
		})
}
