import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTimeout from 'react-timeout'

import { AbsoluteAlert } from './styled'
import { closeAlert } from 'actions/shared'

class FlashAlert extends Component{
    constructor(props){
        super(props)
        this.onDismiss = this.onDismiss.bind(this)
    }

    componentDidUpdate() {
        if(this.props.alert && this.props.alert.show)
            this.props.setTimeout(this.onDismiss.bind(this), 4000)
    }

    onDismiss() { this.props.closeAlert() }

    render(){
        return(
            <div className='d-flex justify-content-center'>
                {
                    this.props.alert && this.props.alert.show ? 
                        <AbsoluteAlert 
                            color={this.props.alert.type} 
                            isOpen={this.props.alert.show} 
                            toggle={this.onDismiss}>
                            {this.props.alert.message}
                        </AbsoluteAlert>
                    :
                        ''
                }
            </div>
        
        )
    }
}

function mapStateToProps({ shared }){
    let { alert } = shared
    return { alert }
}
  
export default(
    connect(
        mapStateToProps, { closeAlert }
    )(
        ReactTimeout(FlashAlert)
    )
)
  