import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Navbar from 'components/molecules/navbar/index'
import Header from 'components/molecules/country_header'
import Help from 'components/organisms/help'
import ContentTabs from 'components/organisms/content_tabs'
import Allowed from 'components/organisms/allowed'
import Access from 'components/organisms/access'
import Footer from 'components/molecules/footer'
import * as routes from 'constants/routes'
import { ContainerDiv, LeftDiv, RightDiv, FooterContainer } from './styled'
import { MAP } from 'constants/index'

class DesktopCountry extends React.Component {
  constructor(props) {
    super(props)
    this.onReturnClick = this.onReturnClick.bind(this)
  }

  onReturnClick() {
    this.props.history.push(routes.MAP)
  }

  render() {
    const language = this.props.current_country.language ? this.props.current_country.language.value : 'es'

    return (
      <>
        <Navbar />
        <Header
          country_code={this.props.current_country.code}
          country_language={language}
          country_level={this.props?.current_country?.causal_level?.value}
          country_name={this.props.current_country.name}
          onReturnClick={this.onReturnClick}
        />
        <ContainerDiv className='row no-gutters my-4'>
          <LeftDiv className='col-4 p-2 p-md-4 disable-scrollbars'>
            <Help help={this.props.current_country.help} language={language} />
          </LeftDiv>
          <RightDiv className='col-8 p-2 p-md-4 disable-scrollbars'>
            <ContentTabs language={language} />
            <div className='py-5'>
              {this.props.content_tab_selected === MAP.TABS.allowed ? (
                <Allowed allowed={this.props.current_country.allowed} language={language} name={this.props.current_country.name} needed={this.props.current_country.needed} />
              ) : (
                <Access access={this.props.current_country.access} language={language} name={this.props.current_country.name} />
              )}
            </div>
          </RightDiv>
        </ContainerDiv>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </>
    )
  }
}

function mapStateToProps({ map, shared }) {
  const { current_country } = map
  const { content_tab_selected } = shared
  return { current_country, content_tab_selected }
}

DesktopCountry = connect(mapStateToProps, {})(DesktopCountry)

export default withRouter(DesktopCountry)
