import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchCountryByCode, cleanCurrentData } from 'actions/map'
import QueriesRules from './queries'
import ReactGA from 'react-ga'

ReactGA.initialize(process.env.REACT_APP_PRD_ANALYTICS_TRACKING_ID)

class CountryIndex extends Component{
    
    componentDidMount(){
        // const viewportmeta = document.querySelector('meta[name=viewport]')
        // viewportmeta.setAttribute('content', "width=device-width, initial-scale=1")
        
        const { country_code } = this.props.match.params
        ReactGA.pageview(`/pais/${country_code}`)
        this.props.fetchCountryByCode(country_code)
    }

    componentWillUnmount(){
        this.props.cleanCurrentData()
    }

    render(){
        return(
            <QueriesRules/>
        )
    }
}

CountryIndex = connect(
    null,
    { fetchCountryByCode, cleanCurrentData }
)(CountryIndex)

export default withRouter(CountryIndex)