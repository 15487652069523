import styled from 'styled-components'
import { THEME } from 'constants/theme'
import { firestore } from 'firebase/index'
;(async () => {
  try {
    const doc = await firestore.collection('configuration').doc('causal_levels').get()
    if (doc.exists) {
      const data = doc.data()
      for (const key in data.catalog) {
        if (Object.hasOwnProperty.call(data.catalog, key)) {
          const element = data.catalog[key]
          THEME.color[`abortion_level_${element.value}`] = element.color
        }
      }
    } else {
      console.log('No such document!')
    }
  } catch (error) {
    console.error('Error fetching document: ', error)
  }
})()

export const ColorLegendCircle = styled.div`
    background-color: ${(props) => props.color};
    height: 1rem;
    width: 1rem;
    @media (max-width: 500px){
        height: 0.6rem;
        width: 0.6rem;
    }
`

export const FooterContainer = styled.div`
    height: 100%;
`

export const StyledSpan = styled.span`
    font-size: 1rem;
    @media (max-width: 500px){
        font-size: 0.7rem;
    }
`

export const GradientBarLatam = styled.div`
    border-radius: 3rem;
    background: linear-gradient(
        to right, 
        ${({ theme }) => theme.color.abortion_level_1} 20%, 
        ${({ theme }) => theme.color.abortion_level_2} 20% 40%, 
        ${({ theme }) => theme.color.abortion_level_2_5} 40% 60%,
        ${({ theme }) => theme.color.abortion_level_3} 60% 80%,
        ${({ theme }) => theme.color.abortion_level_4} 80%  
    );
    height: 1rem;
    width: 100%;
`

export const GradientBarMex = styled.div`
    border-radius: 3rem;
    background: linear-gradient(
        to right, 
        ${({ theme }) => theme.color.abortion_level_1} 25%, 
        ${({ theme }) => theme.color.abortion_level_2} 25% 50%, 
        ${({ theme }) => theme.color.abortion_level_3} 50% 75%,
        ${({ theme }) => theme.color.abortion_level_4} 75%  
    );
    height: 1rem;
    width: 100%;
`
