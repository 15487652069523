import React, { Component } from 'react'
import { connect } from 'react-redux'
import { 
    StopScrolling,
    MapContainer, SVGMapContainer, TitleContainer,
    NavbarContainer, MapGridContainer, LegendContainer, FooterContainer } from './styled'
import Navbar from 'components/organisms/navbar/main_mobile'
import SVGMap from 'components/organisms/svg_map/latinoamerica/mobile'
import WelcomeSVG from 'components/atoms/images/welcome_map'
import IndicatorsSVG from 'components/atoms/images/indicators_map'
import Legend from 'components/molecules/map_legend'
import Footer from 'components/molecules/footer'
import LatinMapData from "assets/map/latin_data"
import Sidebar from 'components/molecules/sidebar/mobile'
import { NAVBAR_TYPE } from 'constants/index'

class HomeMobileView extends Component{
    constructor(props){
        super(props)
        this.state = {
            infoModalOpen: false
        }
        this.onToogle = this.onToogle.bind(this)
    }
    
    onToogle(){
        this.setState({
            infoModalOpen: !this.state.infoModalOpen
        })
    }

    render(){
        return(
            <StopScrolling isOpen={this.props.mobile_sidebar_opened}>
                <NavbarContainer>
                    <Navbar type={NAVBAR_TYPE.countries}/>
                </NavbarContainer>
                
                <div>
                    <Sidebar isOpen={this.props.mobile_sidebar_opened}/>
                    <MapContainer className='d-flex justify-content-center'>
                        <MapGridContainer>
                            <TitleContainer>
                                <WelcomeSVG/>
                            </TitleContainer>
                            <SVGMapContainer>
                                <SVGMap 
                                    formattedGeoData={this.props.countries_svg_json} 
                                    initialGeoData={LatinMapData}/>
                            </SVGMapContainer>
                            
                        </MapGridContainer>
                        
                    </MapContainer>
                </div>
                <LegendContainer>
                    <Legend/>
                </LegendContainer>
                <div className='d-flex justify-content-center py-4'>
                    <IndicatorsSVG/>
                </div>
                <FooterContainer>
                    <Footer/>
                </FooterContainer>
            
            </StopScrolling>
        )
    }
}

function mapStateToProps({ map, shared }){
    const { countries_svg_json } = map
    const { mobile_sidebar_opened } = shared
    
    return { countries_svg_json, mobile_sidebar_opened }
}

HomeMobileView = connect(
    mapStateToProps,
    {}
)(HomeMobileView)

export default HomeMobileView