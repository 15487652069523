import styled from 'styled-components'

export const Container = styled.div`
    background-color: ${props => props.theme.color.primary};
    color: white;
    height: 100%;
    position: relative;
`

export const LogoContainer = styled.div`
    height: 100%;
`