import React, { useEffect } from 'react'
import { slide as SidebarContent } from 'react-burger-menu'
import { useDispatch, useSelector } from 'react-redux'
import SidebarButton from 'components/molecules/sidebar_button/mobile'
import HowInfo from 'components/atoms/images/mobile_how_to'
import { Container, SidebarButtonContainer, Bar, AnimatedSidebarButtonContainer } from './styled'
import { setMobileSidebarStatus } from 'actions/shared'
import { logFirstTimeView } from 'actions/auth'

export default function Sidebar(props){
    const isOpen = useSelector(({ shared }) => shared.sidebar_opened)
    const first_time = useSelector(({ auth }) => auth.first_time)
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(logFirstTimeView())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onBurguerClick = () => {
        dispatch(
            setMobileSidebarStatus(!isOpen)
        )
    }

    return(
        <SidebarContent
                customBurgerIcon={ false }
                customCrossIcon={ false }
                isOpen={ props.isOpen }
                width={ '100%' }
                disableOverlayClick
            >
            <Container className='pt-5 pl-4'>
                <HowInfo/>
                {
                    first_time && !props.isOpen ?
                        <AnimatedSidebarButtonContainer className='d-flex' isOpen={ props.isOpen }>
                            <Bar    className='flex-grow-1 d-flex justify-content-center align-items-center'
                                    onClick={() => onBurguerClick()}>
                                Cerrar
                            </Bar>
                            <SidebarButton/>
                        </AnimatedSidebarButtonContainer>
                        :
                        <SidebarButtonContainer className='d-flex' isOpen={ props.isOpen }>
                            <Bar    className='flex-grow-1 d-flex justify-content-center align-items-center'
                                    onClick={() => onBurguerClick()}>
                                Cerrar
                            </Bar>
                            <SidebarButton/>
                        </SidebarButtonContainer>

                }
            </Container>
        </SidebarContent>
    )
}