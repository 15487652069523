import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import parse from 'html-react-parser'
import Body from 'components/atoms/text/body'
import CollapseHelpIcon from 'components/atoms/images/collapse_help'
import { CollapseContainer, CollapseDivButton } from './styled'
import { buttons } from 'constants/dictionary'

export default props => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const regex = /(<([^>]+)>)/ig
  const hasText = !!props.description.replace(regex, "").length
  
  if(!hasText) return ''

  return (
    <div className='my-3'>
      <CollapseDivButton className='d-flex justify-content-end' onClick={toggle} opened={isOpen}>
        <Body className='mr-3' color='white'>
            {buttons.expand[props.language]}
        </Body>
        <CollapseHelpIcon open={isOpen}/>
      </CollapseDivButton>
      <CollapseContainer>
        <Collapse isOpen={isOpen} className='my-2'>
          <div className='d-flex flex-wrap align-items-center'>
              <Body>{parse(props.description)}</Body>
          </div>
        </Collapse>
      </CollapseContainer>
    </div>
  )
}