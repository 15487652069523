import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchZoneByCode, cleanCurrentData } from 'actions/map'
import QueriesRules from './queries'
import ReactGA from 'react-ga'

ReactGA.initialize(process.env.REACT_APP_PRD_ANALYTICS_TRACKING_ID)

class ZoneIndex extends Component{
    
    componentDidMount(){
        const { country_code, zone_code } = this.props.match.params
        ReactGA.pageview(`/pais/${country_code}/region/${zone_code}`)
        this.props.fetchZoneByCode(country_code, zone_code)
    }

    componentWillUnmount(){
        this.props.cleanCurrentData()
    }

    render(){
        return(
            <QueriesRules/>
        )
    }
}

ZoneIndex = connect(
    null,
    { fetchZoneByCode, cleanCurrentData }
)(ZoneIndex)

export default withRouter(ZoneIndex)