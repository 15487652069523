import React from 'react'
import isEmpty from 'lodash/isEmpty'
import parse from 'html-react-parser'
import ReactPlayer from 'react-player/lazy'
import { show_region } from 'constants/dictionary'
import { TextContainer, VideoContainer } from './styled'
import Subtitle from 'components/atoms/text/subtitle'
import References from 'components/organisms/references'

export default props => {
    if (!props.access || isEmpty(props.access))
        return show_region.no_info
    else
        return(
            <div>
                <TextContainer>
                    <Subtitle 
                        text={
                            show_region.titles.access[props.language]
                            .replace(':region_name', props.name)
                        }/>
                    <div className='my-4'>
                        {parse(props.access.description)}
                    </div>
                    {
                        props.access.video &&
                        <VideoContainer>
                            <ReactPlayer 
                                controls
                                height='auto'
                                url={props.access.video}
                            />
                        </VideoContainer>
                    }
                </TextContainer>
                {
                    (props.access.references && props.access.references.length > 0)
                    &&
                    <References 
                        language={props.language}
                        references={props.access.references}
                    />
                }
            </div>
        )
}