import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Logo from 'components/atoms/images/logo_navbar'
import SearchButton from 'components/atoms/buttons/icon'
import SearchIcon from 'components/atoms/images/search_mobile'
import SearchBar from 'components/organisms/search_input/with_icon_button_mobile'
import { Container, LogoContainer } from './styled'
import { NAVBAR_TYPE } from 'constants/index'
import { setMobileSidebarStatus } from 'actions/shared'
import * as routes from 'constants/routes'

class MainMobileNavbar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            visibleSearch: false,
            countryCode: ''
        }
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onCountrySearch = this.onCountrySearch.bind(this)
        this.onZoneSearch = this.onZoneSearch.bind(this)
    }

    componentDidMount(){
        let country_code
        if(this.props.location.pathname === routes.MEXICO_MAP)
            country_code = 'MEX'
        else
            country_code = this.props.match.params.country_code

        console.log(country_code)
        this.setState({ country_code })
    }

    onCountrySearch(country_code){
        this.changeSearchVisibility()
        setTimeout(() => {
            if(country_code === 'MEX')
                    this.props.history.push(routes.MEXICO_MAP)
            else{
                const route = routes.COUNTRY.replace(':country_code', country_code)
                this.props.history.push(route)
            }
        }, 500)
    }

    onZoneSearch(zone_code){
        this.changeSearchVisibility()
        setTimeout(() => {
                const route = routes.ZONE.replace(':country_code', this.state.country_code).replace(':zone_code', zone_code)
                this.props.history.push(route)
        }, 500)
    }

    onSearchClick(){
        this.changeSearchVisibility()
        this.props.setMobileSidebarStatus(false)
    }

    changeSearchVisibility(){
        this.setState({
            visibleSearch: !this.state.visibleSearch
        })
    }

    render(){
        return(
            <>
                <Container className='d-flex justify-content-between align-items-center px-3'>
                    <LogoContainer className='d-flex align-items-center'>
                        <Logo/>
                    </LogoContainer>
                    <SearchButton 
                        imageComponent={<SearchIcon open={this.state.visibleSearch}/>}
                        onClick={this.onSearchClick}
                    />
                </Container>
                {
                    this.state.visibleSearch &&
                        <SearchBar
                            country_code={this.props.current_country.code}
                            options={this.props.type === NAVBAR_TYPE.countries ? this.props.countries_search_options : this.props.zones_search_options}
                            onSearchSubmit={this.props.type === NAVBAR_TYPE.countries ? this.onCountrySearch : this.onZoneSearch}
                            type={this.props.type}
                        />
                }
            </>
        )
    }
}

function mapStateToProps({ map }){
    const { countries_search_options, zones_search_options, current_country } = map
    
    return { countries_search_options, zones_search_options, current_country }
}

MainMobileNavbar = connect(
    mapStateToProps,
    { setMobileSidebarStatus }
)(MainMobileNavbar)

export default withRouter(MainMobileNavbar)