import { MAP } from 'constants/index'

const INITIAL_STATE = {
	countries_svg_json: {},
	current_country: {},
	zones_svg_json: {},
	current_zone: {},
	current_zones_of_countries: [],
	countries_search_options: [],
	zones_search_options: [],
	mexico_data: {}
}

export default (state = INITIAL_STATE, action) => {
	// console.log('action-->', { action, state })
	switch (action.type) {
		case MAP.FETCH_COUNTRIES_SUCCESS:
			return {
				...state,
				countries_svg_json: action.countries
			}
		case MAP.GET_COUNTRIES_SEARCH_OPTIONS:
			return {
				...state,
				countries_search_options: action.payload
			}

		case MAP.FETCH_ZONES_SUCCESS:
			return {
				...state,
				zones_svg_json: action.zones,
				mexico_data: action.mexico_data
			}
		case MAP.GET_ZONES_SEARCH_OPTIONS:
			return {
				...state,
				zones_search_options: action.payload
			}

		case MAP.FETCH_ZONE_SUCCESS:
			return {
				...state,
				current_country: action.country,
				current_zone: action.zone
			}
		case MAP.FETCH_COUNTRY_SUCCESS:
			return {
				...state,
				current_country: action.country
			}

		case MAP.CLEAN_CURRENT_DATA:
			state = INITIAL_STATE
			return { ...state }

		default:
			return { ...state }
	}
}
