import React, { Component } from 'react'
import { SmallButton } from './styled'

export default class MainSmallButton extends Component {
    render(){
        return(
            <>
                <SmallButton 
                    className='btn'
                    bgColor={this.props.bgColor}
                    onClick={this.props.onClick}
                    type={this.props.type}>
                    {this.props.text}
                </SmallButton>
            </>
        )
    }
}