/*Alerts*/
export const ALERTS = {
  SHOW_ERROR_MESSAGE: 'alert_show_error_message',
  SHOW_SUCCESS_MESSAGE: 'alert_show_success_message',
  CLOSE: 'alert_close'
}

/*Shared*/
export const SHARED = {
  PAGE_IS_LOADING: 'shared_page_is_loading',
  PAGE_STOP_LOADING: 'shared_page_stop_loading',
  CLEAN_CURRENT_DATA: 'shared_clean_current_data',
  SIDE_MENU: {
    MAP: { text: 'Mapa', code: 'map', route: 'mapa' },
    ANALYTICS: { text: 'Analíticos', code: 'analytics', route: 'analiticos' }
  },
  SELECT_MENU_SECTION: 'shared_select_menu_section',
  SET_CONTENT_TAB: 'shared_content_tab',
  SET_SIDEBAR_STATUS: 'shared_sidebar_status',
  SET_MOBILE_SIDEBAR_STATUS: 'shared_mobile_sidebar_status'
}

/*Map*/
export const MAP = {
  INITIAL_ZOOM: 3.5,
  INITIAL_CENTER: [-90, 15],
  STYLE: 'mapbox://styles/dokoeims/ckdqh7law050519od72sakn05',
  MAX_ZOOM_IN: 5.8,
  MAX_ZOOM_OUT: 2.5,
  FETCH_ZONE_SUCCESS: 'map_fetch_zone_success',
  FETCH_COUNTRIES_SUCCESS: 'map_fetch_countries_success',
  FETCH_COUNTRY_SUCCESS: 'map_fetch_country_success',
  ABORTION_LEVEL: 'abortion_level',
  GET_COUNTRIES_SEARCH_OPTIONS: 'map_get_countries_search_options',
  FETCH_ZONES_SUCCESS: 'map_fetch_zones_success',
  GET_ZONES_SEARCH_OPTIONS: 'map_get_zones_search_options',
  TABS: {
    allowed: 'esta_permitido',
    access: 'como_accedo',
    need_company: 'te_acompaniamos'
  },
  CLEAN_CURRENT_DATA: 'map_clean_current_data'
}

export const FIRESTORE = {
  COUNTRIES: 'countries',
  COUNTRIES_SIMPLE: 'countries_simple_data',
  COUNTRY_ZONES: 'zones',
  COUNTRY_ZONES_SIMPLE: 'zones_simple_data'
}

export const COUNTRIES_WITH_ZONES = {
  MXN: 'kcVpj0xQIr43tXhIjLxv'
}

export const NAVBAR_TYPE = {
  countries: 'navbar_paises',
  zones: 'navbar_zonas'
}

export const AUTH = {
  LOG_FIRST_TIME_VIEW: 'auth_log_first_time_view'
}

export const CONTACT_OPTIONS = {
  facebook: 'facebook',
  phone: 'phone',
  email: 'email',
  web: 'web',
  whatsapp: 'whatsapp',
  address: 'address',
  fax: 'fax',
  open: 'open',
  instagram: 'instagram',
  twitter: 'twitter',
  title: 'title'
}

export const FORM = {
  EMAIL_REGEX: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

export const CLOUD_FUNCTIONS_URL = {
  sendContactEmail: process.env.REACT_APP_CLOUD_FUNCTIONS_URL_CONTACT
}

export const CONTACT = {
  SEND_EMAIL_STATUS: 'contact_send_email_status',
  GET_COUNTRIES_OPTIONS: 'contact_get_countries_options'
}
