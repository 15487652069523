import { 
    ALERTS,
    SHARED,
    MAP
} from 'constants/index'

const INITIAL_STATE = { 
    pageLoading: false,
    alert: {
        show: false,
        type: 'light',
        message: ''
    },
    current_section: '',
    content_tab_selected: MAP.TABS.access,
    sidebar_opened: true,
    mobile_sidebar_opened: false
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case SHARED.CLEAN_CURRENT_DATA:
            state = INITIAL_STATE
            return { ...state }
        
        case SHARED.PAGE_IS_LOADING:
            return { ...state, pageLoading: true }

        case SHARED.PAGE_STOP_LOADING:
            return { ...state, pageLoading: false }

        case ALERTS.CLOSE:
            return { ...state, alert: {show: action.payload, type:'light', message: ''} }
            
        case ALERTS.SHOW_ERROR_MESSAGE:
            return { ...state, alert: {show: true, type:'danger', message: action.payload} }

        case ALERTS.SHOW_SUCCESS_MESSAGE:
            return { ...state, alert: {show: true, type:'success', message: action.payload} }

        case SHARED.SELECT_MENU_SECTION:
            return { ...state, current_section: action.payload }

        case SHARED.SET_CONTENT_TAB:
            return { ...state, content_tab_selected: action.payload }

        case SHARED.SET_SIDEBAR_STATUS:
            return { ...state, sidebar_opened: action.payload }

        case SHARED.SET_MOBILE_SIDEBAR_STATUS:
            return { ...state, mobile_sidebar_opened: action.payload }

        default:
            return { ...state }
    }
}
