import styled, { keyframes } from 'styled-components'
import { headShake } from 'react-animations'

export const Container = styled.div`
    background-color: white;
    height: ${({ theme }) => theme.height_porcentage.sidebar}vh;
    outline: 0;
    @media (max-width: 767px){
        height: 100%;
    }
`

export const RowTimeline = styled.div`
    div {
        padding: 0;
        height: ${props => props.theme.help_desktop.timeline_height}px;
    }
    hr {
        border-top: ${props => props.theme.help_desktop.line_weight}px solid ${props => props.theme.color.secondary};
        margin: 0;
        top: ${props => (props.theme.help_desktop.timeline_height/2 - props.theme.help_desktop.line_weight)}px;
        position: relative;
    }
    .col-2 {
        display: flex;
        overflow: hidden;
    }
    .corner {
        border: ${props => props.theme.help_desktop.line_weight}px solid ${props => props.theme.color.secondary};
        width: 100%;
        position: relative;
        border-radius: ${props => props.theme.help_desktop.border_radius}px;
    }
    .top-right {
        right: -50%;
        top: -50%;
    }
    .left-bottom {
        left: -50%;
        top: calc(50% - ${props => props.theme.help_desktop.line_weight}px);
    }
    .top-left {
        left: -50%;
        top: -50%;
    }
    .right-bottom {
        right: -50%;
        top: calc(50% - ${props => props.theme.help_desktop.line_weight}px);
    }
`

export const RowContent = styled.div`
    display: flex;
    .col-2 {
        display: inline-flex;
        align-self: stretch;
        align-items: center;
        justify-content: center;
    }
    .col-2::after {
        content: '';
        position: absolute;
        border-left: ${props => props.theme.help_desktop.line_weight}px solid ${props => props.theme.color.secondary};
        z-index: 1;
    }
    .col-2.full-right::after {
        height: 100%;
        left: calc(50% - ${props => props.theme.help_desktop.line_weight}px);
    }
    .col-2.full-left::after {
        height: 100%;
        right: calc(50% - ${props => props.theme.help_desktop.line_weight}px);
    }
`


export const SidebarButtonContainer = styled.div`
    position: absolute;
    left: 0;
    top: 65%;
    width: ${({ isOpen }) => {
        return isOpen 
        ? '100%'
        : '112%'
    }};
`

const BounceInLeftAnimation = keyframes`${headShake}`
export const AnimatedSidebarButtonContainer = styled(SidebarButtonContainer)`
    animation: infinite 3s ${BounceInLeftAnimation};
`

export const Bar = styled.div`
    background-color: ${({ theme }) => theme.color.secondary};
    color: white;
    cursor: pointer;
    padding-left: 10%;
`