import styled from 'styled-components'

export const CollapseContainer = styled.div`
    border-top: 4px solid ${props => props.theme.color.tertiary};
`

export const CollapseDivButton = styled.div`
    cursor: pointer;
    &:hover{
        opacity: .7;
    }
`