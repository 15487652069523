import throttle from 'lodash/throttle';

export const loadState = () => {
  try{
    const serializedState = localStorage.getItem('ipas-cam-map-state');
    if (serializedState == null) {
      return null;
    }
    else {
      return JSON.parse(serializedState);
    }
  }
  catch(e){
    return null;
  }
};

export const saveState = (state) => {
  try{
    const serializedState = JSON.stringify(state);
    localStorage.setItem('ipas-cam-map-state',serializedState);
  }catch(e){
    console.log(e);
  }
}

export const persistStore = store =>
  store.subscribe(throttle(() => saveState(store.getState()), 10000));
