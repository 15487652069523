import React, { useState } from 'react'
import map from 'lodash/map'
import { Collapse } from 'reactstrap'
import Body from 'components/atoms/text/body'
import Strong from 'components/atoms/text/strong'
import CollapseIcon from 'components/atoms/images/collapse'
import Button from 'components/atoms/buttons/small'
import { CollapseContainer, CollapseDivButton } from './styled'
import { show_region, buttons } from 'constants/dictionary'
import { THEME } from 'constants/theme'

export default props => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <CollapseContainer className='my-5'>
      <CollapseDivButton className='d-flex justify-content-end p-3' onClick={toggle}>
        <Body className='mr-3' color={THEME.color.secondary}>
            {show_region.references[props.language]}
        </Body>
        <CollapseIcon open={isOpen}/>
      </CollapseDivButton>
      <Collapse isOpen={isOpen}>
        <div className='d-flex flex-wrap align-items-center'>
            {renderReferences(props.references, props.language)}
        </div>
      </Collapse>
    </CollapseContainer>
  )
}

function renderReferences(references, language){
    return map(references, (reference, key) => {
        return (
            <div className='d-flex flex-column align-items-center text-center w-50' key={key}>
                <Body className='mb-2'><Strong>{reference.title}</Strong></Body>
                <Body className='mb-2'>{reference.subtitle}</Body>
                <Button onClick={() => onViewClick(reference.reference || reference.url)} text={buttons.view[language]}/>
            </div>
        )
    })
}

function onViewClick(url){
    window.open(url, '_blank')
}