import styled from 'styled-components'

export const TextContainer = styled.div`
    p, span {
        color: ${props => {
            if(props.hover) return 'lightblue'
            else return props.color ? props.color : props.theme.color.label
        }}!important;
        font-weight: normal;
        font-size: 0.8rem;
        line-height: 1.2rem;
        @media (max-width: 779px){
            font-size: 0.7rem;
            line-height: 1.1rem;
        }
    }

    strong {
        color: ${props => {
            if(props.hover) return 'lightblue'
            else return props.color ? props.color : props.theme.color.primary
        }}!important;
        font-family: 'Metropolis Bold';
    }

    a {
        color: ${props => props.theme.color.link}!important;
        text-decoration: underline;
    }
`

export const VideoContainer = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; 
    height: 0;
    iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`