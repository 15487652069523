import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { SVGMap } from 'react-svg-map'
import isEmpty from 'lodash/isEmpty'
import { SVGContainer } from '../styled'
import * as routes from 'constants/routes'

class SVGMapClass extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pointedLocation: null,
			tooltipStyle: {
				display: 'none'
			}
		}
		this.onLocationClick = this.onLocationClick.bind(this)
		this.getLocationClassName = this.getLocationClassName.bind(this)
		this.handleLocationMouseOver = this.handleLocationMouseOver.bind(this)
		this.handleLocationMouseOut = this.handleLocationMouseOut.bind(this)
	}

	onLocationClick(event) {
		if (event.target.attributes.id.value !== 'sombra' && event.target.attributes.id.value !== 'contorno') {
			const clickedLocation = event.target
			const route = routes.ZONE.replace(':country_code', 'MEX').replace(':zone_code', clickedLocation.getAttribute('id'))
			this.props.history.push(route)
		}
	}

	getLocationClassName(location, index) {
		if (location.id === 'sombra') return 'svg-map__shadow'
		else if (location.id === 'contorno') return 'svg-map__borderline'
		else {
			// console.log('getLocationClassName::location-->', location.causal)
			return `svg-map__location svg-map__location__abortion_${location.causal}`
		}
	}

	handleLocationMouseOver(event) {
		event.persist()
		if (event.target.attributes.id.value !== 'sombra' && event.target.attributes.id.value !== 'contorno') {
			const pointedLocation = event.target.attributes.name.value
			this.setState({ pointedLocation })
			const tooltipStyle = {
				display: 'block',
				top: event.clientY + 10,
				left: event.clientX - 10
			}
			this.setState({ tooltipStyle })
		}
	}

	handleLocationMouseOut() {
		this.setState({ pointedLocation: null, tooltipStyle: { display: 'none' } })
	}

	render() {
		return (
			<SVGContainer>
				<SVGMap
					// el prop map necesita un archivo ya con info, de lo contrario crashea,
					// por eso se pasa el inicial si todavía no se tiene el formateado ya con las causales
					map={isEmpty(this.props.formattedGeoData) ? this.props.initialGeoData : this.props.formattedGeoData}
					locationClassName={this.getLocationClassName}
					onLocationClick={this.onLocationClick}
					onLocationMouseOver={this.handleLocationMouseOver}
					onLocationMouseOut={this.handleLocationMouseOut}
				/>
				<div className='block__map__tooltip' style={this.state.tooltipStyle}>
					{this.state.pointedLocation}
				</div>
			</SVGContainer>
		)
	}
}

export default withRouter(SVGMapClass)
