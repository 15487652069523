import styled from 'styled-components'
import LiImage from 'assets/list_item_image.svg'
import LiResponsiveImage from 'assets/list_item_responsive_image.svg'

export const StyledTitle = styled.h1`
    color: ${props => props.color ? props.color : props.theme.color.title};
    font-weight: ${props => props.weight ? props.weight : 'bold'};
    font-size: 2.1875rem;
    line-height: 2.5rem;
`

export const StyledSubtitle = styled.h2`
    color: ${props => props.color ? props.color : props.theme.color.secondary};
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;

    @media (max-width: 779px){
        font-size: 0.9rem;
        line-height: 1.35rem;
    }
`

export const StyledImportantPoint = styled.h3`
    color: ${props => {
        if(props.hover)
            return 'lightblue'
        else if(props.inactive)
            return 'lightgray'
        else
            return props.color ? props.color : props.theme.color.primary;
    }};
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.875rem;
`

export const StyledBodyText = styled.p`
    color: ${props => {
        if(props.hover) return 'lightblue'
        else return props.color ? props.color : props.theme.color.title
    }};
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 1.2rem;
    @media (max-width: 779px){
        font-size: 0.7rem;
        line-height: 1.1rem;
    }
`

export const StyledUl = styled.ul`
    list-style-image: url(${LiImage});
    @media (max-width: 779px){
        list-style-image: url(${LiResponsiveImage});
    }
`

export const StyledStrong = styled.strong`
    color: ${props => props.color ? props.color : props.theme.color.title};
    font-family: 'Metropolis Bold';
`

export const StyledCaliTitle = styled.h1`
    color: ${props => props.color ? props.color : props.theme.color.title};
    font-family: 'Orange Slices';
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    @media (max-width: 779px){
        font-size: 1.6rem;
        line-height: 1.8rem;
    }
`

export const StyledHeaderTitle = styled(StyledTitle)`
    font-family: 'Metropolis Bold';
    font-size: 1.875rem;
    line-height: 2rem;
    @media (max-width: 779px){
        font-size: 1rem;
        line-height: 1.2rem;
    }
`

export const StyledHeaderSubtitle = styled(StyledTitle)`
    font-family: 'Metropolis Medium';
    font-size: 1.2rem;
    line-height: 1.4rem;
    @media (max-width: 779px){
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
`