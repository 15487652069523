import React from 'react'
import ReturnImage from 'components/atoms/images/return_button'
import ReturnButton from 'components/atoms/buttons/icon'
import CaliTitle from 'components/atoms/text/cali_title'
import Subtitle from 'components/atoms/text/header_title'
import Point from 'components/atoms/text/important_point'
import RegionMap from 'components/atoms/images/header_region_map'
import { THEME } from 'constants/theme'
import { country_header } from 'constants/dictionary'
import { Container } from './styled'

export default (props) => {
  return (
    <Container className='row no-gutters px-2 px-md-5 py-2 py-md-4'>
      <div className='col-12 col-md-10 d-flex flex-column justify-content-between'>
        <div>
          <ReturnButton imageComponent={<ReturnImage />} onClick={props.onReturnClick} type='button' />
        </div>
        <div className='d-flex flex-column flex-md-row align-items-center align-items-md-end'>
          <Subtitle color={THEME.color.primary} className='mr-md-3 text-center text-md-left' text={country_header.subtitle[props.country_language]} />
          <div>
            <CaliTitle color={THEME.color.secondary} className='text-uppercase text-center text-md-left mt-1 mt-md-0' text={props.zone_name ? props.zone_name : props.country_name} />
            <div className='mt-1 mb-4 my-md-0 text-center d-md-none'>{props.zone_name && <Point text={props.country_name} />}</div>
          </div>
        </div>
        <div className='d-none d-md-flex'>{props.zone_name && <Point text={props.country_name} />}</div>
      </div>

      <div className='col-12 col-md-2 d-flex align-items-center justify-content-center'>
        <RegionMap code={props.country_code ? props.country_code : props.zone_code} level={props.country_level} />
      </div>
    </Container>
  )
}
