import styled from 'styled-components'

export const MediumButton = styled.button`
    border: solid 0.125rem!important;
    border-radius: 0.8rem!important;
    height: 2.6rem;
    width: 9rem;
`

export const SmallButton = styled.button`
    background-color: ${props => props.bgColor ? props.bgColor : props.theme.color.tertiary};
    border: solid 0.125rem!important;
    border-radius: 0.8rem!important;
    color: ${props => props.color ? props.color : 'white'};
    height: 2.2rem;
    padding: 0;
    width: 8rem;
    &:hover {
        background-color: ${props => props.bgHoverColor ? props.bgHoverColor : props.theme.color.tertiary};
        color: ${props => props.hoverColor ? props.hoverColor : 'white'};
        opacity: .7
    }
`

export const LinkButton = styled.button`
    height: 2.6rem;
`

export const IconButton = styled.button`
    background-color: transparent;
    border: none;
    padding: 0;
`

export const SearchBarIconButton = styled.button`
    background-color: white;
    border: none;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    cursor: unset!important;
    height: ${props => props.theme.desktop_search_button_height}rem;
    padding: 0;
    transform: translateX(-5px);
    width: 3rem;
`

export const MobileSearchIconButton = styled.button`
    background-color: white;
    border: none;
    border-bottom: 1px solid ${props => props.theme.color.secondary};
    border-radius: 0;
    padding: 0;
    transform: translatex(-1px);
    width: 3rem;
    z-index: 1;
`