import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import Shared from './shared'
import Maps from './map'
import Auth from './auth'
import Contact from './contact'

export default combineReducers({
    form: formReducer,
    shared: Shared,
    map: Maps,
    auth: Auth,
    contact: Contact
})