import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import * as routes from 'constants/routes'

import NoAccess from 'components/ecosystems/static/no_access'
import NoPayment from 'components/ecosystems/static/no_payment'
import Map from 'components/ecosystems/map/home'
import MexicoMap from 'components/ecosystems/map/mexico'
import CountryShow from 'components/ecosystems/country'
import ZoneShow from 'components/ecosystems/zone'
import ContactShow from 'components/ecosystems/contact'
import PrivacyShow from 'components/ecosystems/static/privacy'
import UsShow from 'components/ecosystems/static/us'

class MainEcosystem extends Component{
    render(){
        return(
            <>

                <Route
                    exact path={routes.NO_ACCESS}
                    component={() => <NoAccess />}
                />

                <Route
                    exact path={routes.NO_PAYMENT}
                    component={() => <NoPayment />}
                />

                <Route
                    exact path={routes.MAP}
                    component={() => <Map />}
                />

                <Route
                    exact path={routes.MEXICO_MAP}
                    component={() => <MexicoMap />}
                />

                <Route
                    exact path={routes.COUNTRY}
                    component={() => <CountryShow />}
                />

                <Route
                    exact path={routes.ZONE}
                    component={() => <ZoneShow />}
                />

                <Route
                    exact path={routes.CONTACT}
                    component={() => <ContactShow />}
                />

                <Route
                    exact path={routes.PRIVACY}
                    component={() => <PrivacyShow />}
                />

                <Route
                    exact path={routes.US}
                    component={() => <UsShow />}
                />

            </>
        )
    }
}

export default MainEcosystem