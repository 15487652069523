import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import DesktopHome from 'components/ecosystems/map/mexico/desktop/index'
import MobileHome from 'components/ecosystems/map/mexico/mobile/index'
import RotateGIF from 'components/atoms/images/rotate_portrait'
import { GIFContainer } from './styled'


class MexicoQueries extends Component{
    
    componentDidMount(){
        document.querySelector(':root').style
            .setProperty('--vh', window.innerHeight/100 + 'px');
    }

    render(){
        return(
            <>
                <MediaQuery maxDeviceWidth={768}>
                    {/* se ocupa maxAspectRatio por que orientation se rompe en Android con el soft keyboard */}
                    <MediaQuery screen maxAspectRatio='13/9'>
                        <MobileHome/>
                    </MediaQuery>
                    <MediaQuery screen minAspectRatio='13/9'>
                        <GIFContainer className='d-flex justify-content-center align-items-start'>
                            <RotateGIF/>
                        </GIFContainer>
                    </MediaQuery>
                </MediaQuery>
                <MediaQuery minDeviceWidth={769}>
                    <MediaQuery minDeviceHeight={640}>
                        <DesktopHome/>
                    </MediaQuery>
                    <MediaQuery maxDeviceHeight={639}>
                        <GIFContainer className='d-flex justify-content-center'>
                            <RotateGIF/>
                        </GIFContainer>
                    </MediaQuery>
                </MediaQuery>
            </>
        )
    }
}

export default MexicoQueries