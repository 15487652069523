import React from 'react'
import { StyledCaliTitle } from './styled'

export default props => {
    return (
        <StyledCaliTitle 
            className={props.className}
            color={props.color}>
            {props.text}
        </StyledCaliTitle>)
}