import styled from 'styled-components'

export const RowTimeline = styled.div`
    div {
        padding: 0;
        height: ${props => props.theme.help_desktop.timeline_height}px;
    }
    hr {
        border-top: ${props => props.theme.help_desktop.line_weight}px solid ${props => props.theme.color.primary};
        margin: 0;
        top: ${props => (props.theme.help_desktop.timeline_height/2 - props.theme.help_desktop.line_weight)}px;
        position: relative;
    }
    .col-2 {
        display: flex;
        overflow: hidden;
    }
    .corner {
        border: ${props => props.theme.help_desktop.line_weight}px solid ${props => props.theme.color.primary};
        width: 100%;
        position: relative;
        border-radius: ${props => props.theme.help_desktop.border_radius}px;
    }
    .top-right {
        right: -50%;
        top: -50%;
    }
    .left-bottom {
        left: -50%;
        top: calc(50% - ${props => props.theme.help_desktop.line_weight}px);
    }
    .top-left {
        left: -50%;
        top: -50%;
    }
    .right-bottom {
        right: -50%;
        top: calc(50% - ${props => props.theme.help_desktop.line_weight}px);
    }
`

export const RowContent = styled.div`
    display: flex;
    .col-2 {
        display: inline-flex;
        align-self: stretch;
        align-items: center;
        justify-content: center;
    }
    .col-2::after {
        content: '';
        position: absolute;
        border-left: ${props => props.theme.help_desktop.line_weight}px solid ${props => props.theme.color.primary};
        z-index: 1;
    }
    .col-2.full-right::after {
        height: 100%;
        left: calc(50% - ${props => props.theme.help_desktop.line_weight}px);
    }
    .col-2.full-left::after {
        height: 100%;
        right: calc(50% - ${props => props.theme.help_desktop.line_weight}px);
    }
`

export const ContactItem = styled.div`
    a {
        color: ${props => props.theme.color.title}
    }

    p, span {
        color: ${props => props.theme.color.title}!important;
        font-weight: normal;
        font-size: 0.8rem;
        line-height: 1.2rem;
        @media (max-width: 779px){
            font-size: 0.7rem;
            line-height: 1.1rem;
        }
    }

    strong {
        color: ${props => props.theme.color.primary}!important;
        font-family: 'Metropolis Bold';
    }
`

export const BreakWordDiv = styled.div`
    a, p {
        word-break: break-all;
    }
`

const Tab = styled.div`
    background-color: ${props => props.selected ? props.theme.color.active_tab_background : props.theme.color.inactive_tab_background};
    color: ${props => props.selected ? 'white' : props.theme.color.label};
    cursor: ${props => props.selected ? 'default' : 'pointer'};
`

export const Rounded = styled(Tab)`
    border-radius: 3.5rem;
    padding: 0.3rem 2rem;
    margin-bottom: 2rem;
`

export const StrongSubtitle = styled.strong`
    padding-top: 1rem;
`