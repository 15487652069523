import styled from 'styled-components'

export const CollapseContainer = styled.div`
    p, span {
        color: ${props => {
            if(props.hover) return 'lightblue'
            else return props.color ? props.color : props.theme.color.label
        }}!important;
        font-weight: normal;
        font-size: 0.8rem;
        line-height: 1.2rem;
        @media (max-width: 779px){
            font-size: 0.7rem;
            line-height: 1.1rem;
        }
    }

    a {
        color: ${props => props.theme.color.link}!important;
        text-decoration: underline;
    }
`

export const CollapseDivButton = styled.div`
    // background-color: ${props => props.opened ? props.theme.color.tertiary : props.theme.color.secondary};
    border-radius: 0.6rem;
    cursor: pointer;

    p {
        color: ${props => props.theme.color.secondary};
    }
    &:hover {
        opacity: .7;
    }
`