import React from 'react'
import { StyledTabIcon } from './styled'
import ActiveAccessIcon from 'assets/access_active.svg'
import InactiveAccessIcon from 'assets/access_inactive.svg'
import ActiveAllowedIcon from 'assets/allowed_active.svg'
import InactiveAllowedIcon from 'assets/allowed_inactive.svg'
import ActiveHelpIcon from 'assets/help_icon.svg'
import { MAP } from 'constants/index'

export default props => {
    return <StyledTabIcon 
                src={getImage(props.type, props.selected)}
            />
}

function getImage(type, selected){
    if(type === MAP.TABS.access)
        return selected ? ActiveAccessIcon : InactiveAccessIcon
    else if(type === MAP.TABS.allowed)
        return selected ? ActiveAllowedIcon : InactiveAllowedIcon
    else if(type === MAP.TABS.need_company)
        return selected ? ActiveHelpIcon : ActiveHelpIcon
    else
        return ''
}