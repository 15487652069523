import styled from 'styled-components'

export const Container = styled.div`
    background-color: ${props => props.theme.color.primary};
    color: white;
    height: ${props => props.theme.desktop_molecule_navbar_height}rem;
    position: relative;
    @media (max-width: 779px){
        height: 2.8rem;
    }
`

export const LogoContainer = styled.div`
    height: 100%;
`