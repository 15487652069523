import React from 'react'
import { footer } from 'constants/dictionary'
import { UpperFooter, SubFooter } from './styled'
import { CONTACT, PRIVACY, US } from 'constants/routes'
import Logo50 from 'assets/ipas-mexico-logo-ligth.png'
import { firestore } from 'firebase/index'
import ReactHtmlParser from 'react-html-parser'

let footerText = ''

;(async () => {
  try {
    const doc = await firestore.collection('configuration').doc('footerText').get()
    if (doc.exists) {
      const data = doc.data()
      footerText = data.text
      // console.log('data', data)
    } else {
      console.log('No such document!')
    }
  } catch (error) {
    console.error('Error fetching document: ', error)
  }
})()

export default (props) => {
  return (
    <>
      <UpperFooter className='d-flex justify-content-around align-items-center py-4 p-lg-0'>
        <a href={US}>{footer.who_we_are}</a>
        <a href={PRIVACY}>{footer.privacy}</a>
        <a href={CONTACT}>{footer.contact}</a>
      </UpperFooter>
      <SubFooter className='py-2 d-flex flex-column justify-content-around'>
        <div className='d-flex justify-content-center'>
          <div className='text-center px-2 w-100 d-md-none'>
            <div> {ReactHtmlParser(footerText)} </div>
            <div>
              <a href='https://ipasmexico.org/50-anos-de-ipas/' target='_blank' rel='noopener noreferrer'>
                <img src={Logo50} width='150' alt='logo' />
              </a>
            </div>
          </div>
          <div className='text-center px-2 w-75 d-none d-md-block'>
            <div> {ReactHtmlParser(footerText)} </div>
            <div>
              <a href='https://ipasmexico.org/50-anos-de-ipas/' target='_blank' rel='noopener noreferrer'>
                <img src={Logo50} width='150' alt='logo' />
              </a>
            </div>
          </div>
        </div>
        <div className='text-center mt-2'>{footer.copyright}</div>
      </SubFooter>
    </>
  )
}
