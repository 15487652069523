import styled from 'styled-components'
import MapBackground from 'assets/map_background.jpg'

export const NavbarContainer = styled.div`
    height: calc(${props => props.theme.mobile_height_porcentage_mex.navbar} * var(--vh))
`

export const MapContainer = styled.div`
    background-image: url(${MapBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(${props => props.theme.mobile_height_porcentage_mex.map} * var(--vh));
    padding: 1rem 0;
`

export const MapGridContainer = styled.div`
    display:grid;
    grid-template-columns: 37% 53% 10%;
    grid-template-rows: 5% 5% 15% 75%;
    max-width: 100%;
    min-width: 100%;
    
    @media (min-width: 415px) and (max-width: 779px){
        max-width: 85%;
        min-width: 85%;
    }

    @media (max-width: 359px){
        max-width: 90%;
        min-width: 90%;
    }
    
`

export const SVGMapContainer = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 5;
    width: 100%;
`

export const TitleContainer = styled.div`
    align-self: start;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 4;
    text-align: center;
    @media (min-width: 375px) and (min-height: 750px){
        grid-row-start: 2;
        grid-row-end: 4;
    }
`

export const LegendContainer = styled.div`
    height: calc(${props => props.theme.mobile_height_porcentage_mex.legend} * var(--vh))
`

export const MoreDownContainer = styled.div`
    height: calc(${props => props.theme.mobile_height_porcentage_mex.see_more} * var(--vh))
`

export const FooterContainer = styled.div`
    height: 100%;
`

export const ReturnContainer = styled.div`
    align-self: start;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    margin: 0 1rem;
    text-align: start;
`