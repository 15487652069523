import React from 'react'
import { EmbeddedSVGComponent } from './styled'
import { THEME } from 'constants/theme'

import ARG from 'assets/map/countries_svg/ARG.svg'
import ATG from 'assets/map/countries_svg/ATG.svg'
import BHS from 'assets/map/countries_svg/BHS.svg'
import BLZ from 'assets/map/countries_svg/BLZ.svg'
import BOL from 'assets/map/countries_svg/BOL.svg'
import BRA from 'assets/map/countries_svg/BRA.svg'
import BRB from 'assets/map/countries_svg/BRB.svg'
import CHL from 'assets/map/countries_svg/CHL.svg'
import COL from 'assets/map/countries_svg/COL.svg'
import CRI from 'assets/map/countries_svg/CRI.svg'
import CUB from 'assets/map/countries_svg/CUB.svg'
import DMA from 'assets/map/countries_svg/DMA.svg'
import DOM from 'assets/map/countries_svg/DOM.svg'
import ECU from 'assets/map/countries_svg/ECU.svg'
import GTM from 'assets/map/countries_svg/GTM.svg'
import GUF from 'assets/map/countries_svg/GUF.svg'
import GUY from 'assets/map/countries_svg/GUY.svg'
import HND from 'assets/map/countries_svg/HND.svg'
import HTI from 'assets/map/countries_svg/HTI.svg'
import JAM from 'assets/map/countries_svg/JAM.svg'
import KNA from 'assets/map/countries_svg/KNA.svg'
import NIC from 'assets/map/countries_svg/NIC.svg'
import PAN from 'assets/map/countries_svg/PAN.svg'
import PER from 'assets/map/countries_svg/PER.svg'
import PRI from 'assets/map/countries_svg/PRI.svg'
import PRY from 'assets/map/countries_svg/PRY.svg'
import SLV from 'assets/map/countries_svg/SLV.svg'
import SUR from 'assets/map/countries_svg/SUR.svg'
import TTO from 'assets/map/countries_svg/TTO.svg'
import URY from 'assets/map/countries_svg/URY.svg'
import VEN from 'assets/map/countries_svg/VEN.svg'

import MEX_1_1 from 'assets/map/mex_zones_svg/MEX.1_1.svg'
import MEX_2_1 from 'assets/map/mex_zones_svg/MEX.2_1.svg'
import MEX_3_1 from 'assets/map/mex_zones_svg/MEX.3_1.svg'
import MEX_4_1 from 'assets/map/mex_zones_svg/MEX.4_1.svg'
import MEX_5_1 from 'assets/map/mex_zones_svg/MEX.5_1.svg'
import MEX_6_1 from 'assets/map/mex_zones_svg/MEX.6_1.svg'
import MEX_7_1 from 'assets/map/mex_zones_svg/MEX.7_1.svg'
import MEX_8_1 from 'assets/map/mex_zones_svg/MEX.8_1.svg'
import MEX_9_1 from 'assets/map/mex_zones_svg/MEX.9_1.svg'
import MEX_10_1 from 'assets/map/mex_zones_svg/MEX.10_1.svg'
import MEX_11_1 from 'assets/map/mex_zones_svg/MEX.11_1.svg'
import MEX_12_1 from 'assets/map/mex_zones_svg/MEX.12_1.svg'
import MEX_13_1 from 'assets/map/mex_zones_svg/MEX.13_1.svg'
import MEX_14_1 from 'assets/map/mex_zones_svg/MEX.14_1.svg'
import MEX_15_1 from 'assets/map/mex_zones_svg/MEX.15_1.svg'
import MEX_16_1 from 'assets/map/mex_zones_svg/MEX.16_1.svg'
import MEX_17_1 from 'assets/map/mex_zones_svg/MEX.17_1.svg'
import MEX_18_1 from 'assets/map/mex_zones_svg/MEX.18_1.svg'
import MEX_19_1 from 'assets/map/mex_zones_svg/MEX.19_1.svg'
import MEX_20_1 from 'assets/map/mex_zones_svg/MEX.20_1.svg'
import MEX_21_1 from 'assets/map/mex_zones_svg/MEX.21_1.svg'
import MEX_22_1 from 'assets/map/mex_zones_svg/MEX.22_1.svg'
import MEX_23_1 from 'assets/map/mex_zones_svg/MEX.23_1.svg'
import MEX_24_1 from 'assets/map/mex_zones_svg/MEX.24_1.svg'
import MEX_25_1 from 'assets/map/mex_zones_svg/MEX.25_1.svg'
import MEX_26_1 from 'assets/map/mex_zones_svg/MEX.26_1.svg'
import MEX_27_1 from 'assets/map/mex_zones_svg/MEX.27_1.svg'
import MEX_28_1 from 'assets/map/mex_zones_svg/MEX.28_1.svg'
import MEX_29_1 from 'assets/map/mex_zones_svg/MEX.29_1.svg'
import MEX_30_1 from 'assets/map/mex_zones_svg/MEX.30_1.svg'
import MEX_31_1 from 'assets/map/mex_zones_svg/MEX.31_1.svg'
import MEX_32_1 from 'assets/map/mex_zones_svg/MEX.32_1.svg'

export default (props) => {
  const regions = {
    ARG,
    ATG,
    BHS,
    BLZ,
    BOL,
    BRA,
    BRB,
    CHL,
    COL,
    CRI,
    CUB,
    DMA,
    DOM,
    ECU,
    GTM,
    GUF,
    GUY,
    HND,
    HTI,
    JAM,
    KNA,
    NIC,
    PAN,
    PER,
    PRI,
    PRY,
    SLV,
    SUR,
    TTO,
    URY,
    VEN,
    'MEX.1_1': MEX_1_1,
    'MEX.2_1': MEX_2_1,
    'MEX.3_1': MEX_3_1,
    'MEX.4_1': MEX_4_1,
    'MEX.5_1': MEX_5_1,
    'MEX.6_1': MEX_6_1,
    'MEX.7_1': MEX_7_1,
    'MEX.8_1': MEX_8_1,
    'MEX.9_1': MEX_9_1,
    'MEX.10_1': MEX_10_1,
    'MEX.11_1': MEX_11_1,
    'MEX.12_1': MEX_12_1,
    'MEX.13_1': MEX_13_1,
    'MEX.14_1': MEX_14_1,
    'MEX.15_1': MEX_15_1,
    'MEX.16_1': MEX_16_1,
    'MEX.17_1': MEX_17_1,
    'MEX.18_1': MEX_18_1,
    'MEX.19_1': MEX_19_1,
    'MEX.20_1': MEX_20_1,
    'MEX.21_1': MEX_21_1,
    'MEX.22_1': MEX_22_1,
    'MEX.23_1': MEX_23_1,
    'MEX.24_1': MEX_24_1,
    'MEX.25_1': MEX_25_1,
    'MEX.26_1': MEX_26_1,
    'MEX.27_1': MEX_27_1,
    'MEX.28_1': MEX_28_1,
    'MEX.29_1': MEX_29_1,
    'MEX.30_1': MEX_30_1,
    'MEX.31_1': MEX_31_1,
    'MEX.32_1': MEX_32_1
  }

  return <EmbeddedSVGComponent src={regions[props.code]} color={`${THEME.color[`abortion_level_${props.level}`]}`} />
}
