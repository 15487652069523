import React from 'react'
import ReactDom from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { 
    loadState,
    persistStore } from 'local-storage'
import AppEnvironment from 'components/environment/app'
import reducers from 'reducers'

const persistedState = loadState()
let initialLocalState = persistedState && persistedState.auth ? 
                            { auth: persistedState.auth } 
                            : {}

const createStoreWithMiddleware = applyMiddleware(ReduxThunk)(createStore)
const store = createStoreWithMiddleware(
  reducers,
  initialLocalState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
store.subscribe(() => { persistStore(store) })

ReactDom.render(
    <Provider store={store}>
        <BrowserRouter>
            <AppEnvironment/>
        </BrowserRouter>
    </Provider>
    , 
    document.getElementById('root')
)
