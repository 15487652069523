import styled from 'styled-components'

export const MainContainer = styled.div`
    background-color: ${props => props.theme.color.background};
    min-height: 100vh;
`

export const FooterContainer = styled.div`
    height: ${props => props.theme.footer_container}vh;
`

export const RowTimeline = styled.div`
    div {
        padding: 0;
        height: ${props => props.theme.help_desktop.timeline_height}px;
    }
    hr {
        border-top: ${props => props.theme.contact.line_weight}px solid ${props => props.theme.color.tertiary};
        margin: 0;
        top: ${props => (props.theme.help_desktop.timeline_height/2 - props.theme.contact.line_weight)}px;
        position: relative;
    }
    .col-2 {
        display: flex;
        overflow: hidden;
    }
    .corner {
        border: ${props => props.theme.contact.line_weight}px solid ${props => props.theme.color.tertiary};
        width: 100%;
        position: relative;
        border-radius: ${props => props.theme.contact.border_radius}px;
    }
    .top-right {
        right: -50%;
        top: -50%;
    }
    .left-bottom {
        left: -50%;
        top: calc(50% - ${props => props.theme.contact.line_weight}px);
    }
    .top-left {
        left: -50%;
        top: -50%;
    }
    .right-bottom {
        right: -50%;
        top: calc(50% - ${props => props.theme.contact.line_weight}px);
    }
`

export const RowContent = styled.div`
    display: flex;
    .col-2, .col-md-2 {
        display: inline-flex;
        align-self: stretch;
        justify-content: center;
    }
    .col-2::after, .col-md-2::after {
        content: '';
        position: absolute;
        border-left: ${props => props.theme.contact.line_weight}px solid ${props => props.theme.color.tertiary};
        z-index: 1;
    }
    .col-2.full-right::after, .col-md-2.full-right::after {
        height: 100%;
        left: calc(50% - ${props => props.theme.contact.line_weight}px);
    }
    .col-2.full-left::after, .col-md-2.full-left::after {
        height: 100%;
        right: calc(50% - ${props => props.theme.contact.line_weight}px);
    }
`

export const FormContainer = styled.div`
    min-height: 70vh;
`

export const TitleDiv = styled.div`
    padding: 5rem 5rem;
    @media (max-width: 320px){
        padding: 1.5rem 0rem 1rem 0.3rem;
    }
    @media (min-width: 321px) and (max-width: 425px){
        padding: 1.5rem 0rem 1rem 0.5rem;
    }
    @media (min-width: 426px) and (max-width: 768px){
        padding: 1.5rem 0rem 1rem 2rem;
    }
    @media (min-width: 769px) and (max-width: 1024px){
        padding: 3rem 0rem 2rem 2rem;
    }
    @media (min-width: 1025px) and (max-width: 1440px){
        padding: 4rem 4rem;
    }
`