import React from 'react'
import { connect } from 'react-redux'
import { setContentTab } from 'actions/shared'
import { Container, LeftRounded, RightRounded } from './styled'
import TabImage from 'components/atoms/images/tab_icon'
import { MAP } from 'constants/index'
import { tabs } from 'constants/dictionary'

class ContentTabs extends React.Component {
    constructor(props){
        super(props)
        this.selectTab = this.selectTab.bind(this)
    }

    componentWillUnmount(){
        this.props.setContentTab(MAP.TABS.allowed)
    }

    selectTab(type){
        this.props.setContentTab(type)
    }

    render(){
        return(
            <Container className='row no-gutters'>
                <LeftRounded 
                    className='col-6 d-flex justify-content-center align-items-center'
                    onClick={() => this.selectTab(MAP.TABS.access)} 
                    selected={this.props.content_tab_selected === MAP.TABS.access}>
                        <TabImage 
                            selected={this.props.content_tab_selected === MAP.TABS.access}
                            type={MAP.TABS.access}/>
                        <p className='ml-3'>
                            {tabs.access[this.props.language]}
                        </p>
                </LeftRounded>
                <RightRounded 
                    className='col-6 d-flex justify-content-center align-items-center'
                    onClick={() => this.selectTab(MAP.TABS.allowed)} 
                    selected={this.props.content_tab_selected === MAP.TABS.allowed}>
                        <p className='mr-3'>
                            {tabs.allowed[this.props.language]}
                        </p>
                        <TabImage 
                            selected={this.props.content_tab_selected === MAP.TABS.allowed}
                            type={MAP.TABS.allowed}/>
                </RightRounded>
            </Container>
        )
    }
}

function mapStateToProps({ shared }){
    const { content_tab_selected } = shared
    return { content_tab_selected }
}

ContentTabs = connect(
    mapStateToProps,
    { setContentTab }
)(ContentTabs)

export default ContentTabs