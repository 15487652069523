import { createGlobalStyle } from 'styled-components'

import OrangesSlicesTTF from  'assets/fonts/orangeslices.ttf'
import OrangesSlicesEOT from 'assets/fonts/orangeslices.eot'
import OrangesSlicesOTF from 'assets/fonts/orangeslices.otf'
import OrangesSlicesWOFF from  'assets/fonts/orangeslices.woff'
import OrangesSlicesWOFF2 from 'assets/fonts/orangeslices.woff2'
import OrangesSlicesSVG from 'assets/fonts/orangeslices.svg'

import MetropolisRegularTTF from  'assets/fonts/metropolis-regular.ttf'
import MetropolisRegularEOT from 'assets/fonts/metropolis-regular.eot'
import MetropolisRegularOTF from 'assets/fonts/metropolis-regular.otf'
import MetropolisRegularWOFF from  'assets/fonts/metropolis-regular.woff'
import MetropolisRegularWOFF2 from 'assets/fonts/metropolis-regular.woff2'
import MetropolisRegularSVG from 'assets/fonts/metropolis-regular.svg'

import MetropolisMediumTTF from  'assets/fonts/metropolis-medium.ttf'
import MetropolisMediumEOT from 'assets/fonts/metropolis-medium.eot'
import MetropolisMediumOTF from 'assets/fonts/metropolis-medium.otf'
import MetropolisMediumWOFF from  'assets/fonts/metropolis-medium.woff'
import MetropolisMediumWOFF2 from 'assets/fonts/metropolis-medium.woff2'
import MetropolisMediumSVG from 'assets/fonts/metropolis-medium.svg'

import MetropolisBoldTTF from  'assets/fonts/metropolis-bold.ttf'
import MetropolisBoldEOT from 'assets/fonts/metropolis-bold.eot'
import MetropolisBoldOTF from 'assets/fonts/metropolis-bold.otf'
import MetropolisBoldWOFF from  'assets/fonts/metropolis-bold.woff'
import MetropolisBoldWOFF2 from 'assets/fonts/metropolis-bold.woff2'
import MetropolisBoldSVG from 'assets/fonts/metropolis-bold.svg'

export default createGlobalStyle`
    @font-face {
        font-family: 'Orange Slices';
        src: url(${OrangesSlicesEOT}); /* IE9 Compat Modes */
        src: local('Orange Slices Normal'), local('OrangeSlices-Normal'),
            url('${OrangesSlicesEOT}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('${OrangesSlicesWOFF2}') format('woff2'), /* Super Modern Browsers */
            url('${OrangesSlicesWOFF}') format('woff'), /* Modern Browsers */
            url('${OrangesSlicesTTF}') format('truetype'), /* Safari, Android, iOS */
            url('${OrangesSlicesOTF}') format('opentype'),
            url('${OrangesSlicesSVG}#OrangeSlices') format('svg');
            font-weight: normal;
            font-style: normal;
    }

    @font-face {
        font-family: 'Metropolis Regular';
        src: url(${MetropolisRegularEOT}); /* IE9 Compat Modes */
        src: local('Metropolis Regular'), local('Metropolis-Regular'),
            url('${MetropolisRegularEOT}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('${MetropolisRegularWOFF2}') format('woff2'), /* Super Modern Browsers */
            url('${MetropolisRegularWOFF}') format('woff'), /* Modern Browsers */
            url('${MetropolisRegularTTF}') format('truetype'), /* Safari, Android, iOS */
            url('${MetropolisRegularOTF}') format('opentype'),
            url('${MetropolisRegularSVG}#Metropolis') format('svg');
            font-weight: normal;
            font-style: normal;
    }

    @font-face {
        font-family: 'Metropolis Medium';
        src: url(${MetropolisMediumEOT}); /* IE9 Compat Modes */
        src: local('Metropolis Medium'), local('Metropolis-Medium'),
            url('${MetropolisMediumEOT}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('${MetropolisMediumWOFF2}') format('woff2'), /* Super Modern Browsers */
            url('${MetropolisMediumWOFF}') format('woff'), /* Modern Browsers */
            url('${MetropolisMediumTTF}') format('truetype'), /* Safari, Android, iOS */
            url('${MetropolisMediumOTF}') format('opentype'),
            url('${MetropolisMediumSVG}#Metropolis') format('svg');
            font-weight: normal;
            font-style: normal;
    }

    @font-face {
        font-family: 'Metropolis Bold';
        src: url(${MetropolisBoldEOT}); /* IE9 Compat Modes */
        src: local('Metropolis Bold'), local('Metropolis-Bold'),
            url('${MetropolisBoldEOT}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('${MetropolisBoldWOFF2}') format('woff2'), /* Super Modern Browsers */
            url('${MetropolisBoldWOFF}') format('woff'), /* Modern Browsers */
            url('${MetropolisBoldTTF}') format('truetype'), /* Safari, Android, iOS */
            url('${MetropolisBoldOTF}') format('opentype'),
            url('${MetropolisBoldSVG}#Metropolis') format('svg');
            font-weight: normal;
            font-style: normal;
    }

    html, body {
        font-family: 'Metropolis Medium', sans-serif;
        font-size: 1.125rem;
        font-weight: normal;
        font-style: normal;
        height: 100%;
    }
    
    h1 {
        font-size: 4.375rem;
        font-weight: 700;
    }
    
    h2 {
        font-size: 3.125rem;
        font-weight: 700;
    }
    
    h3 {
        font-size: 2.5rem;
        font-weight: 600;
    }
    
    h4 {
        font-size: 1.875rem;
        font-weight: 500;
    }
    
    h5 {
        font-size: 1.5625rem;
    }
    
    h6 {
        font-size: 1.25rem;
    }
    
    h1, h2, h3, h4, h5, h6, p {
        margin:0;
    }
    
    input:focus {
        outline:none;
    }
`