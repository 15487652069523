import React from 'react'
import Select from 'react-select'
import { THEME } from 'constants/theme'

const colourStyles = {
    control: styles => {
      return {
        ...styles,
        backgroundColor: 'white',
        border: 'none',
        borderBottom: `1px solid ${THEME.color.secondary}`,
        borderRadius: 0,
        // boxShadow: '-27px 24px 39px -2px rgba(0,0,0,0.4)',
        fontSize: '1rem',
        marginLeft: 'auto',
        minHeight: '1.5rem',
        height: '3.4rem',
        outline: 'none',
        padding: '1rem',
        textAlign: 'center',
        zIndex: '1'

      }
    },
    indicatorSeparator: styles => {
      return {
        display: 'none'
      }
    },
    valueContainer: styles => {
      return {
        ...styles,
        fontSize: '1rem',
        outline: 'none',
      }
    },
    placeholder: styles => {
      return {
        ...styles,
        color: `${THEME.color.placeholder}`,
        fontSize: '0.7rem'
      }
    },
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? THEME.color.secondary
          : isSelected
          ? THEME.color.secondary
          : null,
        color: isFocused || isSelected ? 'white' : 'black',
        fontSize: '0.7rem',
        outline: 'none',
      }
    },
    dropdownIndicator: styles => {
        return {
            display:'none'
        }
    },
    menu: styles => {
        return {
            ...styles,
            borderRadius: 0,
            width: '100vw',
            top: '2.95rem'
        }
    }
}

export default field => {

    return(
        <Select 
            blurInputOnSelect={false}
            className='w-100'
            closeMenuOnSelect={field.isMultiple ? false : true}
            defaultMenuIsOpen={true}
            isMulti={field.isMultiple}
            name={field.input.name}
            noOptionsMessage={() => 'Ningún resultado'}
            onBlurResetsInput={false}
            onChange={value => {
                field.input.onChange(value)
                field.forzeSubmit(value)
            }}
            onCloseResetsInput={false}
            onFocus={field.input.onFocus}
            options={field.options}
            placeholder={field.placeholder}
            styles={colourStyles}
            value={field.input.value}
        />
    )
}