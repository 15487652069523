import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from './global_style'

import MainEcosystem from 'components/ecosystems/main'
// import FlashAlert from 'components/molecules/alert'
import PageLoader from 'components/molecules/page_loader'

import 'components/environment/app.scss'
import { THEME } from 'constants/theme'

class App extends Component {
    render(){
        return(
            <ThemeProvider theme={THEME}>
                <GlobalStyle/>
                {/* <FlashAlert/> */}
                <PageLoader/>
                <MainEcosystem/>
            </ThemeProvider>
        )
    }
}

//withRouter debe envolver a connect para que App reconozca las rutas y enrutamiento
export default withRouter(
    connect(null, {  } )(App)
)