import React, { useEffect, useState } from 'react'
import Navbar from 'components/molecules/navbar/index'
import Footer from 'components/molecules/footer'
import { FooterContainer } from './styled'
import { firestore } from 'firebase/index'

const HtmlContent = () => {
  const [htmlContent, setHtmlContent] = useState('')

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const doc = await firestore.collection('content').doc('privacy').get()
        if (doc.exists) {
          const data = doc.data()
          setHtmlContent(data.text)
        } else {
          console.log('No such document!')
        }
      } catch (error) {
        console.error('Error fetching document: ', error)
      }
    }

    fetchHtmlContent()
  }, [])

  return (
    <div className='d-flex flex-column'>
      <Navbar />
      <div className='p-4 p-lg-5' dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </div>
  )
}

export default HtmlContent
