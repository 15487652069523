import styled from 'styled-components'
import React from 'react'

export const StyledLogo = styled.img`
    cursor: pointer;
    object-fit: contain;
    width: ${(props) => props.theme.desktop_navbar_logo_size}rem;

    @media (max-width: 767px){
        width: 4rem;
    }
`

export const StyledWelcomeTitle = styled.img`
    height: 20vh;
    object-fit: contain;
    width: auto;
    max-width: -webkit-fill-available;

    @media (max-width: 1024px){
        height: 14vh;
    }

    @media (max-width: 767px){
        height: auto;
        width: 36vw;
    }
`

export const StyledIndicators = styled.img`
    height: 40vh;
    object-fit: contain;
    width: auto;
    max-width: -webkit-fill-available;

    @media (max-width: 1024px){
        height: 27.5vh;
    }
    @media (max-width: 767px){
        height: 100%;
        width: 80%;
    }
`

export const StyledMexicoIndicators = styled.img`
    height: 30vh;
    object-fit: cover;
    width: auto;

    @media (max-width: 1024px){
        height: 27.5vh;
    }
    @media (max-width: 767px){
        height: 100%;
        width: 80%;
    }
`

export const StyledInfo = styled.img`
    cursor: pointer;
    height: 8vh;
    object-fit: contain;
    width: auto;
    &:hover {
        opacity: 0.7
    }
`

export const StyledImgButton = styled.img`
    object-fit: contain;
    width: 1.2rem;
`

export const StyledModal = styled.img`
    object-fit: cover;
    width: 100%;
`

export const ContainerCloseModal = styled.div`
    border-radius: 50%;
    cursor: pointer;
    height: 3rem;
    width: 3rem;
`

export const StyledCloseModal = styled.img`
    transform: translateY(-0.25rem) translateX(0.5rem);
    width: 1rem;
    &:hover {
        opacity: 0.7
    }
`

export const StyledRotatePortrait = styled.img`
    height: 80vh;
    width: auto;
`

export const StyledCloseIcon = styled.img`
    object-fit: contain;
    width: 1.2rem;
`

export const StyledSearchIcon = styled.img`
    object-fit: contain;
    width: 2rem;
`

export const StyledMexicoTitle = styled.img`
    height: 10vh;
    object-fit: cover;
    width: auto;
    @media (max-width: 767px){
        height: auto;
        width: 50vw;
    }
`

export const StyledReturn = styled.div`
    background-image: url(${(props) => props.image});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    height: 2.2rem;
    width: 2.2rem;

    &:hover {
        background-image: url(${(props) => props.hover_image});
        opacity: 0.7;
    }

    @media (max-width: 767px){
        height: 1.8rem;
        width: 1.8rem;
    }
`

export const StyledHeaderRegion = styled.img`
    fill: ${(props) => props.theme.color.abortion_level_}${(props) => props.level};
    height: 8rem;
    object-fit: contain;
    width: 8rem;
    @media (max-width: 767px){
        height: 6rem;
        width: 6rem;
    }
`

export const StyledHeaderRegionEmbed = styled.embed`
    // fill: ${(props) => props.theme.color.abortion_level_}${(props) => props.level};
    height: 8rem;
    object-fit: contain;
    width: 8rem;
    @media (max-width: 767px){
        height: 6rem;
        width: 6rem;
    }
`

export const EmbeddedSVGComponent = ({ src, color }) => {
  const embed = document.querySelector('embed')
  if (embed) {
    embed.addEventListener('load', function () {
      const svgDoc = embed.getSVGDocument()
      if (svgDoc) {
        const paths = svgDoc.querySelectorAll('path')
        paths.forEach((path) => {
          if (color !== undefined) {
            path.style.fill = color
          }
        })
      }
    })
  }

  return <StyledHeaderRegionEmbed src={src} type='image/svg+xml' />
}

export const StyledTabIcon = styled.img`
    height: 2.5rem;
    object-fit: contain;
    width: auto;

    @media (max-width: 767px){
        height: 1.6rem;
    }
`

export const StyledCollapseImg = styled.img`
    height: auto;
    object-fit: contain;
    width: 1.2rem;
`

export const StyledNeedHelpImg = styled.img`
    height: 3.6rem;
    object-fit: contain;
    transform: translateY(-40%);
    width: 3.6rem;
    z-index: 5;
`

export const StyledCollapseHelpImg = styled.img`
    height: auto;
    object-fit: contain;
    width: 1rem;
`

export const StyledContactHelpImg = styled.img`
    height: 0.9rem;
    object-fit: contain;
    width: 0.9rem;

    @media (max-width: 767px){
        height: 0.8rem;
        width: 0.8rem;
    }
`

export const StyledMoreDown = styled.img`
    height: 1rem;
    object-fit: contain;
    width: auto;
`

export const StyledContactImg = styled.img`
    height: 10rem;
    margin-top: 5rem;
    object-fit: contain;
    position: absolute;
    width: 10rem;
    z-index: 5;

    @media (max-width: 320px){
        margin-top: 0rem;
        height: 2.6rem;
        width: 2.6rem;
        transform: translateY(-25%) translateX(-50%);
        z-index: 5;
    }
    @media (min-width: 321px) and (max-width: 575px){
        margin-top: 0rem;
        height: 3rem;
        width: 3rem;
        transform: translateY(-25%) translateX(-50%);
        z-index: 5;
    }
    @media (min-width: 576px) and (max-width: 768px){
        margin-top: 3rem;
        height: 4rem;
        width: 4rem;
    }
    @media (min-width: 769px) and (max-width: 1024px){
        margin-top: 3.4rem;
        height: 5rem;
        width: 5rem;
    }
    @media (min-width: 1025px) and (max-width: 1440px){
        margin-top: 4rem;
        height: 8rem;
        width: 8rem;
    }
`

export const StyledContactSentImg = styled.img`
    height: 10rem;
    object-fit: contain;
    width: 10rem;

    @media (max-width: 320px){
        height: 2.6rem;
        width: 2.6rem;
    }
    @media (min-width: 321px) and (max-width: 425px){
        height: 3rem;
        width: 3rem;
    }
    @media (min-width: 426px) and (max-width: 768px){
        height: 4rem;
        width: 4rem;
    }
    @media (min-width: 769px) and (max-width: 1024px){
        height: 5rem;
        width: 5rem;
    }
    @media (min-width: 1025px) and (max-width: 1440px){
        height: 8rem;
        width: 8rem;
    }
`

export const StyledCircleSearchIcon = styled.img`
    height: 3rem;
    left: -2rem;
    object-fit: contain;
    position: absolute;
    width: 3rem;
    z-index: 1;
`

export const StyledIndicatorIcon = styled.img`
height: 1rem;
object-fit: contain;
position: absolute;
right: 1rem;
width: 1rem;
&:hover{
    opacity: 0.7;
}
`

export const StyledExpandSidebar = styled.img`
    object-fit: contain;
    width: 0.9rem;
    @media (max-width: 767px){
        width: 0.6rem;
    }
`
export const StyledSidebarWhatIcon = styled.img`
    height: 3rem;
    left: 35%;
    object-fit: contain;
    position: absolute;
    top: -15%;
    width: 3rem;
    z-index: 1;
`

export const StyledSidebarHowIcon = styled.img`
    height: 3rem;
    left: 40%;
    object-fit: contain;
    position: absolute;
    top: -15%;
    width: 3rem;
    z-index: 1;
`

export const StyledSidebarWhatInfo = styled.img`
    margin-left: -10%;
    width: 130%;
`

export const StyledSidebarHowInfo = styled.img`
    margin-left: -10%;
    width: 120%;
`

export const StyledSidebarMobileHowInfo = styled.img`
    margin: 0 auto;
    display: block;
`
