import styled from 'styled-components'
import MapBackground from 'assets/map_background.jpg'

export const NavbarContainer = styled.div`
    height: calc(${props => props.theme.mobile_height_porcentage.navbar} * var(--vh))
`

export const MapContainer = styled.div`
    background-image: url(${MapBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(${props => props.theme.mobile_height_porcentage.map} * var(--vh));
    padding: 1rem 0;
`

export const MapGridContainer = styled.div`
    display:grid;
    grid-template-columns: 57% 33% 10%;
    grid-template-rows: 5% 15% 65% 15%;
    max-width: 100%;
    min-width: 100%;
    
    @media (min-width: 415px) and (max-width: 779px){
        max-width: 65%;
        min-width: 65%;
    }
    @media (max-width: 359px){
        max-width: 90%;
        min-width: 90%;
    }
    
`

export const SVGMapContainer = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
    width: 100%;
    transform: rotate(11.4deg) translateX(-10vw) translateY(6vh);

    @media (min-width: 375px) and (min-height: 750px){
        transform: rotate(8deg) translateX(-7vw) translateY(14vh);
    }
    
`

export const TitleContainer = styled.div`
    align-self: start;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    text-align: center;
    @media (min-width: 375px) and (min-height: 750px){
        grid-row-start: 2;
        grid-row-end: 4;
    }
`

export const SidebarButtonContainer = styled.div`
    position: absolute;
    left: 0;
    top: 75%;
`

export const LegendContainer = styled.div`
    height: calc(${props => props.theme.mobile_height_porcentage.legend} * var(--vh))
`

export const FooterContainer = styled.div`
    height: 100%;
`

export const StopScrolling = styled.div`
    height: ${({ isOpen }) => isOpen ? '100vh' : 'inherit'}; 
    overflow-y: ${({ isOpen }) => isOpen ? 'hidden' : 'inherit'}; 
`