import styled from 'styled-components'

export const ContainerDiv = styled.div`
    height: 100vh;
    margin: 0;
    overflow: hidden;
    position: relative;
`

export const FooterContainer = styled.div`
    height: 30vh;
`

export const CollapseDivButton = styled.div`
    background-color: ${props => props.opened ? props.theme.color.tertiary : props.theme.color.inactive_tab_background};
    cursor: pointer;

    p {
        color: ${props => props.opened ? 'white' : props.theme.color.title};
    }
`