import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import DesktopZone from 'components/ecosystems/zone/desktop/index'
import MobileZone from 'components/ecosystems/zone/mobile/index'

class Queries extends Component{
    render(){
        return(
            <>
                <MediaQuery maxDeviceWidth={768}>
                    <MobileZone/>
                </MediaQuery>
                <MediaQuery minDeviceWidth={769}>
                    <DesktopZone/>
                </MediaQuery>
            </>
        )
    }
}

export default Queries