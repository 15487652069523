import styled from 'styled-components'
import { Modal, ModalHeader } from 'reactstrap'

export const StyledModal = styled(Modal)`
    transform: translateY(100%)!important;
`

export const StyledImageModal = styled(Modal)`
    transform: translateY(-10%)!important;
    max-width: 700px!important;
    .modal-content{
        background-color: transparent!important;
        border: none;
        width: 120%!important;
    }
`

export const StyledModalHeader = styled(ModalHeader)`
    border-bottom: none!important;
    display: block;
    padding: 0;
`