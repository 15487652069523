import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from './styled'
import ExpandIcon from 'components/atoms/images/expand_sidebar'
import ContractIcon from 'components/atoms/images/contract_sidebar'
import { setSidebarStatus } from 'actions/shared'
import { logFirstTimeView } from 'actions/auth'

export default function SidebarButton(){
    const dispatch = useDispatch()
    const isOpen = useSelector(({ shared }) => shared.sidebar_opened)
    const firstTime = useSelector(({ auth }) => auth.first_time)

    useEffect(() => {
        if(firstTime)
            dispatch(
                setSidebarStatus(true)
            )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onBurguerClick = () => {
        dispatch(
            setSidebarStatus(!isOpen)
        )
        if(firstTime)
            dispatch(logFirstTimeView())
    }

    return(
            <Container
                className='d-flex justify-content-center align-items-center px-2 py-3'
                isOpen={isOpen}
                onClick={() => onBurguerClick()}>
                {
                    isOpen ?
                        <ContractIcon/>
                    :
                        <ExpandIcon/>
                }
            </Container>
    )
}