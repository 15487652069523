import React from 'react'
import { connect } from 'react-redux'
import { StyledHomeMobileAlert } from './styled'
import { mobile_alert } from 'constants/dictionary'
import { logFirstTimeView } from 'actions/auth'

class HomeMobileAlert extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            visible: true,
        }
        this.onDismiss = this.onDismiss.bind(this)
    }

    onDismiss(){
        this.setState({
            visible: false
        })
        this.props.logFirstTimeView()
    }

    render(){
        return (
                this.props.first_time
                ?   <StyledHomeMobileAlert isOpen={this.state.visible} toggle={this.onDismiss}>
                        <p>{mobile_alert.title_mexico}</p>
                        <p>{mobile_alert.option_1}</p>
                        <p>{mobile_alert.option_2}</p>
                    </StyledHomeMobileAlert>
                :   ''
        )
    }
}

function mapStateToProps({ auth }){
    const { first_time } = auth
    
    return { first_time }
}

HomeMobileAlert = connect(
    mapStateToProps,
    { logFirstTimeView }
)(HomeMobileAlert)

export default HomeMobileAlert