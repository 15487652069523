import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Navbar from 'components/molecules/navbar/index'
import Header from 'components/molecules/country_header'
import { Collapse } from 'reactstrap'
import SectionIcon from 'components/atoms/images/collapse_section_type'
import Indicator from 'components/atoms/images/collapse_section_indicator'
import Body from 'components/atoms/text/body'
import Help from 'components/organisms/help'
import Allowed from 'components/organisms/allowed'
import Access from 'components/organisms/access'
import Footer from 'components/molecules/footer'
import * as routes from 'constants/routes'
import { CollapseDivButton, FooterContainer } from './styled'
import { tabs } from 'constants/dictionary'
import { MAP } from 'constants/index'
import { scroller, Element } from 'react-scroll'

class DesktopZone extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            isOpen: {
                allowed: false,
                access: false,
                need_company: false,
            }
        }
        this.onReturnClick = this.onReturnClick.bind(this)
        this.toggle = this.toggle.bind(this)
        this.onExited = this.onExited.bind(this)
    }

    onReturnClick(){
        // if(this.props.current_country.code === 'MEX')
        this.props.history.push(routes.MEXICO_MAP)
    }

    toggle(type){
        this.setState({
            isOpen: {
                allowed: false,
                access: false,
                need_company: false,
                [type] : !this.state.isOpen[type]
            }
        })
    }

    onExited(){
        scroller.scrollTo('collapsablesZones', {
            duration: 800,
            smooth: true
        })
    }

    render(){
        const language = this.props.current_country.language
                            ? this.props.current_country.language.value
                            : 'es'

        return(
            <>
                <Navbar/>
                <Header
                    zone_code={this.props.current_zone.code}
                    country_language={language}
                    zone_level={this.props.current_zone.level}
                    zone_name={this.props.current_zone.name}
                    country_name={this.props.current_country.name}
                    onReturnClick={this.onReturnClick}
                />
                <div className='p-2'>
                    <Element name='collapsablesZones'></Element>

                    <div className='my-2'>
                        <CollapseDivButton 
                            className='row no-gutters px-2 py-3 align-items-center' 
                            onClick={() => this.toggle(MAP.TABS.access)} 
                            opened={this.state.isOpen[MAP.TABS.access]}>
                                <div className='col-2 text-center'>
                                    <SectionIcon type={MAP.TABS.access} open={this.state.isOpen[MAP.TABS.access]}/>
                                </div>
                                <div className='col-8'>
                                    <Body>{tabs.access[language]}</Body>
                                </div>
                                <div className='col-2 text-right'>
                                    <Indicator open={this.state.isOpen[MAP.TABS.access]}/>
                                </div>
                        </CollapseDivButton>
                        <Collapse 
                            className='my-2 py-4'
                            isOpen={this.state.isOpen[MAP.TABS.access]} 
                            onExited={this.onExited}
                        >
                            <Access
                                access={this.props.current_zone.access}
                                language={language}
                                name={this.props.current_zone.name}
                            />
                        </Collapse>
                    </div>

                    <div className='my-2'>
                        <CollapseDivButton 
                            className='row no-gutters px-2 py-3 align-items-center' 
                            onClick={() => this.toggle(MAP.TABS.allowed)} 
                            opened={this.state.isOpen[MAP.TABS.allowed]}>
                                <div className='col-2 text-center'>
                                    <SectionIcon type={MAP.TABS.allowed} open={this.state.isOpen[MAP.TABS.allowed]}/>
                                </div>
                                <div className='col-8'>
                                    <Body>{tabs.allowed[language]}</Body>
                                </div>
                                <div className='col-2 text-right'>
                                    <Indicator open={this.state.isOpen[MAP.TABS.allowed]}/>
                                </div>
                            
                        </CollapseDivButton>
                        <Collapse 
                            className='my-2 py-4'
                            isOpen={this.state.isOpen[MAP.TABS.allowed]} 
                            onExited={this.onExited}
                        >
                            <Allowed
                                allowed={this.props.current_zone.allowed}
                                language={language}
                                name={this.props.current_zone.name}
                                needed={this.props.current_zone.needed}
                            />
                        </Collapse>
                    </div>
                    
                    <div className='my-2'>
                        <CollapseDivButton 
                            className='row no-gutters px-2 py-3 align-items-center' 
                            onClick={() => this.toggle(MAP.TABS.help)} 
                            opened={this.state.isOpen[MAP.TABS.help]}>
                                <div className='col-2 text-center'>
                                    <SectionIcon type={MAP.TABS.help} open={this.state.isOpen[MAP.TABS.help]}/>
                                </div>
                                <div className='col-8'>
                                    <Body>{tabs.need_company[language]}</Body>
                                </div>
                                <div className='col-2 text-right'>
                                    <Indicator open={this.state.isOpen[MAP.TABS.help]}/>
                                </div>
                        </CollapseDivButton>
                        <Collapse 
                            className='my-2 py-4'
                            isOpen={this.state.isOpen[MAP.TABS.help]} 
                            onExited={this.onExited}
                        >
                            <Help 
                                help={this.props.current_zone.help}
                                language={language}/>
                        </Collapse>
                    </div>
                    
                </div>
                <FooterContainer>
                    <Footer/>
                </FooterContainer>
            </>
        )
    }
}

function mapStateToProps({ map, shared }){
    const { current_country, current_zone } = map
    const { content_tab_selected } = shared
    return { current_country, current_zone, content_tab_selected }
}

DesktopZone = connect(
    mapStateToProps,
    {}
)(DesktopZone)

export default withRouter(DesktopZone)