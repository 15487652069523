import React from 'react'
import { connect } from 'react-redux'

import Form from 'components/organisms/contact_form'

import { TitleDiv, RowContent, RowTimeline, FormContainer } from './styled'
import Subtitle from 'components/atoms/text/header_title'
import { THEME } from 'constants/theme'
import ContactIcon from 'components/atoms/images/contact'
import { contact_view } from 'constants/dictionary'

class DesktopView extends React.Component{

    render(){
        return(
            <>
                <div>
                    <RowContent className='row no-gutters justify-content-end'>
                        <div className='col-10'>
                            {
                                !this.props.send_email_status &&
                                <TitleDiv>
                                    <Subtitle 
                                        color={THEME.color.primary}
                                        text={contact_view.title.es}/>
                                </TitleDiv>
                            }
                        </div>
                        <div className='col-2'>
                            <div className='col-2 text-center full-right'></div>
                        </div>
                    </RowContent>
                    <RowTimeline className='row no-gutters justify-content-end'>
                        <div className='col-2'>
                            <div className='corner right-bottom'></div>
                        </div>
                        <div className={`col-8`}>
                            <hr/>
                        </div>
                        <div className='col-2'>
                            <div className='corner top-left'></div>
                        </div>
                    </RowTimeline>
                </div>
                <div>
                    <RowContent className='row no-gutters'>
                        <div className='col-2'>
                            <div className='col-2 text-center full-left'></div>
                            <ContactIcon/>
                        </div>
                        <div className='col-9'>
                            <FormContainer className='row no-gutters'>
                                <div className='col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
                                    <Form options={this.props.country_options}/>
                                </div>
                            </FormContainer>
                        </div>
                    </RowContent>
                </div>
            </>
        )
    }
}

function mapStateToProps({ contact }){
    const { send_email_status, country_options } = contact
    return { country_options, send_email_status }
}

DesktopView = connect(
    mapStateToProps,
    { }
)(DesktopView)

export default DesktopView