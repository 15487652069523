import styled from 'styled-components'

export const SVGContainer = styled.div`
    height: 100%;
    max-height: 100%;

    .svg-map {
        width: 100%;
        height: 100%;
        stroke: white;
        stroke-width: 1;
        stroke-linecap: round;
        stroke-linejoin: round; 
    }
    
    @media (max-width: 767px){
        .svg-map {
            width: 100%;
            height: auto;
        }
    }
    
    .svg-map__shadow {
        fill: rgba( 247, 155, 209, 0.15);
        stroke: white;
        stroke-width: 0;
    }
    
    .svg-map__location {
        fill: #e2e5e4;
        cursor: pointer; 
    }
    
    .svg-map__borderline {
        fill: white;
    }
    
    .svg-map__location__abortion_1 {
        fill: ${props => props.theme.color.abortion_level_1};
        cursor: pointer; 
    }
    
    .svg-map__location__abortion_2 {
        fill: ${props => props.theme.color.abortion_level_2};
        cursor: pointer; 
    }
    
    .svg-map__location__abortion_2_5 {
        fill: ${props => props.theme.color.abortion_level_2_5};
        cursor: pointer; 
    }
    
    .svg-map__location__abortion_3 {
        fill: ${props => props.theme.color.abortion_level_3};
        cursor: pointer; 
    }
    
    .svg-map__location__abortion_4 {
        fill: ${props => props.theme.color.abortion_level_4};
        cursor: pointer; 
    }
    
    .svg-map__location:focus, .svg-map__location:hover {
        fill-opacity: 70%;
        outline: 0; 
    }
    
    .svg-map__location[aria-checked=true] {
        fill: #f4bc44; 
    }
    
    .block__map__tooltip {
        position: fixed;
        padding: 0.25rem 1rem;
        background-color: #F591B5;
        border: 1px solid white;
        border-radius: 2rem;
        color: white;
    }
`