import React from 'react'
import MediaQuery from 'react-responsive'
import { StyledMexicoIndicators } from './styled'
import HowToSVG from 'assets/mexico_how_to_use.svg'

export default props => {
    return (
        <>
            <MediaQuery maxDeviceWidth={767}>
                <StyledMexicoIndicators src={HowToSVG} className='my-2'/>
            </MediaQuery>
            <MediaQuery minDeviceWidth={768}>
                <StyledMexicoIndicators src={HowToSVG}/>
            </MediaQuery>
        </>
    )
}