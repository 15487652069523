import styled from 'styled-components'

export const UpperFooter = styled.div`
    background-color: ${(props) => props.theme.color.upper_footer};
    font-size: 0.9rem;
    height: 30%;
    a {
        color: white;
    }

    @media (max-width: 500px){
        a {
            font-size: 0.8rem;
        }
    }

    @media (max-width: 400px){
        a {
            font-size: 0.6rem;
        }
    }
`

export const SubFooter = styled.div`
    background-color: ${(props) => props.theme.color.sub_footer};
    color: white;
    font-size: 0.6rem;
    height: 100%;
    @media (max-width: 500px){
        font-size: 0.6rem;
    }
`
