import React from 'react'
import map from 'lodash/map'
import filter from 'lodash/filter'
import parse from 'html-react-parser'
import TabImage from 'components/atoms/images/tab_icon'
import Body from 'components/atoms/text/body'
import Strong from 'components/atoms/text/strong'
import Collapse from 'components/organisms/collapse_help'
import ContactImage from 'components/atoms/images/help_contact'
import Subtitle from 'components/atoms/text/subtitle'
import { RowTimeline, RowContent, ContactItem, BreakWordDiv, Rounded, StrongSubtitle } from './styled'
import { show_region } from 'constants/dictionary'
import { THEME } from 'constants/theme'
import { CONTACT_OPTIONS } from 'constants/index'
import { MAP } from 'constants/index'

function renderHeader(language) {
	return (
		<>
			<RowContent className='row justify-content-end'>
				<div className='col-11'>
					<div className='d-none d-md-block'>
						<Rounded className='d-flex justify-content-center align-items-center' selected={true}>
							<TabImage selected={true} type={MAP.TABS.need_company} />
							<p className='ml-3'>{show_region.titles.help[language]}</p>
						</Rounded>
					</div>

					<div className='d-block d-md-none mb-4'>
						<Subtitle text={show_region.titles.help[language]} />
					</div>
				</div>
				<div className='col-8'>
					<Body>{show_region.descriptions.help[language]}</Body>
				</div>
				<div className='col-2'>
					<div className='col-2 text-center full-right'></div>
				</div>
			</RowContent>
			<RowTimeline className='row'>
				<div className='col-2'>
					<div className='corner right-bottom'></div>
				</div>
				<div className='col-8'>
					<hr />
				</div>
				<div className='col-2'>
					<div className='corner top-left'></div>
				</div>
			</RowTimeline>
		</>
	)
}

function renderHelpContacts(contacts, language) {
	return map(contacts, (contact, key) => {
		const isLast = key === contacts.length - 1
		if ((key + 1) % 2 === 0) return renderEvenHelp(contact, isLast, language, key)
		else return renderOddHelp(contact, isLast, language, key)
	})
}

function renderEvenHelp(contact, isLast, language, key) {
	return (
		<div key={key}>
			<RowContent className='row justify-content-end'>
				<div className='col-8'>
					<Body>
						<Strong color={THEME.color.secondary}>{contact.title}</Strong>
					</Body>
					<ContactItem>{contact.contacts && contact.contacts.length > 0 && renderContacts(contact.contacts, key)}</ContactItem>
					{contact.description && <Collapse description={contact.description} language={language} />}
				</div>
				<div className='col-2'>
					<div className='col-2 text-center full-right'></div>
				</div>
			</RowContent>
			<RowTimeline className='row justify-content-end'>
				{!isLast && (
					<div className='col-2'>
						<div className='corner right-bottom'></div>
					</div>
				)}
				<div className={`col-${isLast ? '9' : '8'}`}>
					<hr />
				</div>
				<div className='col-2'>
					<div className='corner top-left'></div>
				</div>
			</RowTimeline>
		</div>
	)
}

function renderOddHelp(contact, isLast, language, key) {
	return (
		<div key={key}>
			<RowContent className='row'>
				<div className='col-2'>
					<div className='col-2 text-center full-left'></div>
				</div>
				<div className='col-8'>
					<Body>
						<Strong color={THEME.color.secondary}>{contact.title}</Strong>
					</Body>
					<ContactItem>{contact.contacts && contact.contacts.length > 0 && renderContacts(contact.contacts, key)}</ContactItem>
					{contact.description && <Collapse description={contact.description} language={language} />}
				</div>
			</RowContent>
			<RowTimeline className='row'>
				<div className='col-2'>
					<div className='corner top-right'></div>
				</div>
				<div className={`col-${isLast ? '9' : '8'}`}>
					<hr />
				</div>
				{!isLast && (
					<div className='col-2'>
						<div className='corner left-bottom'></div>
					</div>
				)}
			</RowTimeline>
		</div>
	)
}

function renderContacts(contacts, father_key) {
	return map(contacts, (contact, key) => {
		if (contact.type)
			return (
				<div className='d-flex align-items-start my-2' key={`${father_key}_${key}`}>
					{contact.type.value !== CONTACT_OPTIONS.title ? (
						<>
							<ContactImage type={contact.type.value} className='mr-2' />
							<BreakWordDiv className='d-flex flex-wrap'>
								<Body>{renderContactType(contact)}</Body>
							</BreakWordDiv>
						</>
					) : (
						<BreakWordDiv className='d-flex flex-wrap mt-2'>
							<Body>{renderContactType(contact)}</Body>
						</BreakWordDiv>
					)}
				</div>
			)
		else return ''
	})
}

function renderContactType(contact) {
	let tag
	switch (contact.type.value) {
		case CONTACT_OPTIONS.web:
		case CONTACT_OPTIONS.facebook:
		case CONTACT_OPTIONS.instagram:
		case CONTACT_OPTIONS.twitter:
			tag = (
				<a href={contact.content} target='_blank' rel='noopener noreferrer'>
					{contact.title}
				</a>
			)
			break
		case CONTACT_OPTIONS.fax:
		case CONTACT_OPTIONS.address:
			tag = <span>{contact.content}</span>
			break
		case CONTACT_OPTIONS.phone:
			tag = <a href={`tel:${contact.content}`}>{contact.content}</a>
			break
		case CONTACT_OPTIONS.whatsapp:
			tag = <a href={`whatsapp://send?abid=${contact.content}&text=Hola`}>{contact.content}</a>
			break
		case CONTACT_OPTIONS.email:
			tag = <a href={`mailto:${contact.content}`}>{contact.content}</a>
			break
		case CONTACT_OPTIONS.open:
			tag = contact.content ? <span>{parse(contact.content)}</span> : <span> </span>
			break
		case CONTACT_OPTIONS.title:
			tag = <StrongSubtitle>{parse(contact.content)}</StrongSubtitle>
			break
		default:
			break
	}
	return tag
}

export default (props) => {
	if (!props.help || props.help.length <= 0) return ''
	else {
		let helpArray = filter(props.help, (help) => {
			return help.description || help.title || (help.contacts && help.contacts.length > 0)
		})
		return (
			<>
				{renderHeader(props.language)}
				{renderHelpContacts(helpArray, props.language)}
			</>
		)
	}
}
