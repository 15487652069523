import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import DesktopCountry from 'components/ecosystems/country/desktop/index'
import MobileCountry from 'components/ecosystems/country/mobile/index'

class HomeIndex extends Component{
    render(){
        return(
            <>
                <MediaQuery maxDeviceWidth={768}>
                    <MobileCountry/>
                </MediaQuery>
                <MediaQuery minDeviceWidth={769}>
                    <DesktopCountry/>
                </MediaQuery>
            </>
        )
    }
}

export default HomeIndex