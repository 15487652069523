import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchAllCountries } from 'actions/map'
import QueriesRules from './queries'
import ReactGA from 'react-ga'
// import { MEXICO_MAP } from 'constants/routes'

ReactGA.initialize(process.env.REACT_APP_PRD_ANALYTICS_TRACKING_ID)

class HomeIndex extends Component{
    
    componentDidMount(){
        ReactGA.pageview('/mapa-latinoamerica')
        this.props.fetchAllCountries()
    }

    componentWillUnmount(){
    //     if(this.props.history.location.pathname !== MEXICO_MAP){
    //         const viewportmeta = document.querySelector('meta[name=viewport]')
    //         viewportmeta.setAttribute('content', "width=device-width, initial-scale=0")
    //     }
        window.location.assign(this.props.history.location.pathname)
    }

    render(){
        return(
            <QueriesRules/>
        )
    }
}

HomeIndex = connect(
    null,
    { fetchAllCountries }
)(HomeIndex)

export default withRouter(HomeIndex)