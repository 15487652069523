import React, { Component } from 'react'
import { IconButton } from './styled'

export default class MainIconButton extends Component {
    render(){
        return(
            <>
                <IconButton 
                    className='btn'
                    onClick={this.props.onClick}
                    type={this.props.type}>
                    {this.props.imageComponent}
                </IconButton>
            </>
        )
    }
}