import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { 
    MapContainer, SVGMapContainer, TitleContainer,
    NavbarContainer, MapGridContainer, LegendContainer, MoreDownContainer, FooterContainer, ReturnContainer } from './styled'
import Navbar from 'components/organisms/navbar/main_mobile'
import Alert from 'components/molecules/alert/mexico_mobile'
import ReturnImage from 'components/atoms/images/return_button'
import ReturnButton from 'components/atoms/buttons/icon'
import SVGMap from 'components/organisms/svg_map/mexico/mobile'
import WelcomeSVG from 'components/atoms/images/mexico_title'
import MexicoContent from 'components/molecules/mexico_info'
import Legend from 'components/molecules/map_legend/mexico'
import IndicatorsSVG from 'components/atoms/images/mexico_indicators_map'
import Footer from 'components/molecules/footer'
import MoreDown from 'components/atoms/images/see_more'
import MexMapData from "assets/map/mex_data"
import { NAVBAR_TYPE } from 'constants/index'
import * as routes from 'constants/routes'

class HomeMobileView extends Component{
    constructor(props){
        super(props)
        this.onReturnClick = this.onReturnClick.bind(this)
    }

    onReturnClick(){
        this.props.history.push(routes.MAP)
    }

    render(){
        return(
            <>
                <NavbarContainer>
                    <Navbar type={NAVBAR_TYPE.zones}/>
                </NavbarContainer>
                <Alert/>
                <MapContainer className='d-flex justify-content-center'>
                    <MapGridContainer>
                        <ReturnContainer>
                            <ReturnButton 
                                imageComponent={<ReturnImage/>} 
                                onClick={this.onReturnClick} 
                                type='button' />
                        </ReturnContainer>
                        <TitleContainer>
                            <WelcomeSVG/>
                        </TitleContainer>
                        <SVGMapContainer>
                            <SVGMap 
                                formattedGeoData={this.props.zones_svg_json} 
                                initialGeoData={MexMapData}/>
                        </SVGMapContainer>
                    </MapGridContainer>
                </MapContainer>
                <LegendContainer>
                    <Legend/>
                </LegendContainer>
                <MoreDownContainer className='d-flex justify-content-center align-items-center'>
                    <MoreDown/>
                </MoreDownContainer>
                <div className='d-flex justify-content-center py-4'>
                    <IndicatorsSVG/>
                </div>
                <MexicoContent/>
                <FooterContainer>
                    <Footer/>
                </FooterContainer>
            </>
        )
    }
}

function mapStateToProps({ map }){
    const { zones_svg_json } = map
    
    return { zones_svg_json }
}

HomeMobileView = connect(
    mapStateToProps,
    {}
)(HomeMobileView)

export default withRouter(HomeMobileView)