export const NO_ACCESS = '/sin-permisos'
export const NO_PAYMENT = '/sin-servicio'

export const MAP = '/'
export const MEXICO_MAP = '/mapa-mexico'
export const COUNTRY = '/pais/:country_code'

export const ZONE = '/pais/:country_code/region/:zone_code'
export const CONTACT = '/contacto'
export const US = '/quienes-somos'
export const PRIVACY = '/privacidad'