import React from 'react'
import { connect } from 'react-redux'
import { fetchContactCountriesOptions } from 'actions/contact'
import Navbar from 'components/molecules/navbar/index'
import Desktop from './desktop'
import Mobile from './mobile'
import MediaQuery from 'react-responsive'
import Footer from 'components/molecules/footer'
import { MainContainer, FooterContainer } from './styled'

class ContactView extends React.Component {
  componentDidMount() {
    this.props.fetchContactCountriesOptions()
  }

  render() {
    return (
      <MainContainer className='d-flex flex-column'>
        <Navbar />
        <MediaQuery maxWidth={575}>
          <Mobile />
        </MediaQuery>
        <MediaQuery minWidth={576}>
          <Desktop />
        </MediaQuery>
        <FooterContainer className='mt-auto'>
          <Footer />
        </FooterContainer>
      </MainContainer>
    )
  }
}

ContactView = connect(null, { fetchContactCountriesOptions })(ContactView)

export default ContactView
