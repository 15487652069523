import React from 'react'
import { StyledContactHelpImg } from './styled'
import Web from 'assets/contact_web.svg'
import Facebook from 'assets/contact_facebook.svg'
import Email from 'assets/contact_email.svg'
import Phone from 'assets/contact_phone.svg'
import Fax from 'assets/contact_fax.svg'
import Address from 'assets/contact_address.svg'
import Whatsapp from 'assets/contact_whatsapp.svg'
import Open from 'assets/contact_open.svg'
import Twitter from 'assets/contact_twitter.svg'
import Instagram from 'assets/contact_instagram.svg'
import { CONTACT_OPTIONS } from 'constants/index'

export default props => {
    return <StyledContactHelpImg 
        className={props.className}
        src={
            props.type === CONTACT_OPTIONS.web
            ? Web
            : props.type === CONTACT_OPTIONS.facebook
            ? Facebook
            : props.type === CONTACT_OPTIONS.email
            ? Email
            : props.type === CONTACT_OPTIONS.phone
            ? Phone
            : props.type === CONTACT_OPTIONS.fax
            ? Fax
            : props.type === CONTACT_OPTIONS.address
            ? Address
            : props.type === CONTACT_OPTIONS.whatsapp
            ? Whatsapp
            : props.type === CONTACT_OPTIONS.open
            ? Open
            : props.type === CONTACT_OPTIONS.twitter
            ? Twitter
            : props.type === CONTACT_OPTIONS.instagram
            ? Instagram
            : Web
        }/>
}