import React, { Component } from 'react'
import { connect } from 'react-redux'
import { MapContainer, SVGMapContainer, TitleContainer, IndicatorsContainer, InfoContainer, NavbarContainer, MapGridContainer, LegendContainer, FooterContainer } from './styled'
import Navbar from 'components/organisms/navbar/main_desktop'
import SVGMap from 'components/organisms/svg_map/latinoamerica/index'
import WelcomeSVG from 'components/atoms/images/welcome_map'
import IndicatorsSVG from 'components/atoms/images/indicators_map'
import InfoImage from 'components/atoms/images/info_button'
import InfoButton from 'components/atoms/buttons/icon'
import Modal from 'components/molecules/modal/only_image'
import ModalImage from 'components/atoms/images/info_modal'
import Lengend from 'components/molecules/map_legend'
import Footer from 'components/molecules/footer'
import LatinMapData from 'assets/map/latin_data'
import Sidebar from 'components/molecules/sidebar'
import { NAVBAR_TYPE } from 'constants/index'

class HomeDesktopView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      infoModalOpen: false
    }
    this.onToogle = this.onToogle.bind(this)
  }

  onToogle() {
    this.setState({
      infoModalOpen: !this.state.infoModalOpen
    })
  }

  render() {
    return (
      <>
        <NavbarContainer className='bg-'>
          <Navbar type={NAVBAR_TYPE.countries} />
        </NavbarContainer>
        <div className='d-flex'>
          <Sidebar isOpen={this.props.sidebar_opened} />

          <div className='flex-grow-1'>
            <MapContainer className='d-flex justify-content-center'>
              <MapGridContainer className='bg-'>
                <SVGMapContainer className='bg-'>
                  <SVGMap formattedGeoData={this.props.countries_svg_json} initialGeoData={LatinMapData} />
                </SVGMapContainer>
                <TitleContainer className='bg-'>
                  <WelcomeSVG />
                </TitleContainer>
                <IndicatorsContainer className='bg-'>
                  <IndicatorsSVG />
                </IndicatorsContainer>
                <InfoContainer>
                  <InfoButton imageComponent={<InfoImage />} onClick={this.onToogle} type='button' />
                </InfoContainer>
                <Modal image={<ModalImage />} modal={this.state.infoModalOpen} toggle={this.onToogle} />
              </MapGridContainer>
            </MapContainer>
            <LegendContainer className='bg-'>
              <Lengend />
            </LegendContainer>
            <FooterContainer className='bg-'>
              <Footer />
            </FooterContainer>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps({ map, shared }) {
  const { countries_svg_json } = map
  const { sidebar_opened } = shared

  return { countries_svg_json, sidebar_opened }
}

HomeDesktopView = connect(mapStateToProps, {})(HomeDesktopView)

export default HomeDesktopView
