import axios from 'axios'
import { firestore } from 'firebase/index'
import { SHARED, ALERTS, CLOUD_FUNCTIONS_URL, CONTACT, FIRESTORE } from 'constants/index'
import { contact_view } from 'constants/dictionary'
import { getSearchOptions } from 'helpers/index'

export function sendContactEmail(message, callback){
  return dispatch => {
    dispatch({ type: SHARED.PAGE_IS_LOADING })
    const headers = { 
        headers: { 
            'Content-Type':'application/json'
        }
    }
    const data = JSON.stringify(message)
    const endpoint = `${CLOUD_FUNCTIONS_URL.sendContactEmail}`
    let axios_request = axios.create({
        validateStatus : status => { return status <= 500 }
    })
    axios_request.post(endpoint, data, headers)
    .then(response => {
        if(response.data.success){
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            callback(true)
        }
        else {
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: contact_view.form.error.es
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            
        }

    })
    .catch(error => {
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
        dispatch({
            type: ALERTS.SHOW_ERROR_MESSAGE,
            payload: contact_view.form.error.es
        })
        console.log('Error mayor a 500 desde servidor.', error)
    })
  }
}

export function changeSendContactEmailStatus(status){
    return dispatch =>{
        dispatch({
            type: CONTACT.SEND_EMAIL_STATUS,
            payload: status
        })
    }
}

export function fetchContactCountriesOptions(){
    let countriesArray = []
    let query
    return dispatch => {
        dispatch({ type: SHARED.PAGE_IS_LOADING })
        query = firestore.collection(FIRESTORE.COUNTRIES_SIMPLE)
        query.get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                let country_id = { id:doc.id }
                let data = doc.data()
                countriesArray.push({ ...country_id, ...data })
            })
            
            let contactOptions = getSearchOptions(countriesArray)
            contactOptions.push({label: "Otro", value: "OTHER"}) //de ultimo momento requirieon esta opción
            dispatch({
                type: CONTACT.GET_COUNTRIES_OPTIONS,
                payload: contactOptions
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            
        })
        .catch(error => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
                type: ALERTS.SHOW_ERROR_MESSAGE,
                payload: 'No existe información.'
            })
        })
    }
}