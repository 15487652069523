import React from 'react'
import { connect } from 'react-redux'

import Form from 'components/organisms/contact_form'

import { TitleDiv, FormContainer, RowTimeline } from './styled'
import Subtitle from 'components/atoms/text/header_title'
import { THEME } from 'constants/theme'
import ContactIcon from 'components/atoms/images/contact'
import { contact_view } from 'constants/dictionary'

class DesktopView extends React.Component{

    render(){
        return(
            <div className='d-flex flex-column'>
                {
                    !this.props.send_email_status &&
                    <>
                        
                        <TitleDiv className='px-3 mt-3'>
                            <Subtitle 
                                color={THEME.color.primary}
                                text={contact_view.title.es}/>
                        </TitleDiv>
                            
                        <RowTimeline className='text-center mt-3 mb-5'>
                            <hr/>
                            <ContactIcon/>
                        </RowTimeline>
                    </>
                }
                <FormContainer className='px-3'>
                    <Form options={this.props.country_options}/>
                </FormContainer> 
            </div>
        )
    }
}

function mapStateToProps({ contact }){
    const { send_email_status, country_options } = contact
    return { country_options, send_email_status }
}

DesktopView = connect(
    mapStateToProps,
    { }
)(DesktopView)

export default DesktopView