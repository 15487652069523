import React from 'react'
import Select from 'react-select'
import { THEME } from 'constants/theme'
import Indicator from 'components/atoms/images/select_indicator'

const colourStyles = {
    control: styles => {
      return {
        ...styles,
        backgroundColor: 'white',
        border: 'none',
        borderBottomRightRadius: 25,
        borderTopRightRadius: 25,
        boxShadow: "none",
        fontSize: `${THEME.desktop_search_font_size}rem`,
        marginLeft: 'auto',
        minHeight: `${THEME.desktop_search_height}rem`,
        paddingLeft: '1rem',
        paddingBottom: '0rem',
        textAlign: 'right',
        width: '25rem'
      }
    },
    indicatorSeparator: styles => {
      return {
        display: 'none'
      }
    },
    valueContainer: styles => {
      return {
        ...styles,
        top: '-20%'
      }
    },
    placeholder: styles => {
      return {
        ...styles,
        color: `${THEME.color.placeholder}`,
        fontSize: `${THEME.desktop_search_font_size}rem`,
      }
    },
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? THEME.color.secondary
          : isSelected
          ? THEME.color.secondary
          : null,
        color: isFocused || isSelected ? 'white' : 'black',
        fontSize: `${THEME.desktop_search_font_size}rem`,
      }
    },
    dropdownIndicator: (styles, { isFocused, onMouseOver }) => {
      return {
       ...styles,
        color: onMouseOver ? THEME.color.secondary : THEME.color.secondary,
        hoverColor: THEME.color.secondary,
        opacity: isFocused ? 0.7 : 1
      }
    },
    menu: styles => {
        return {
            ...styles,
            right: 0,
            width: '25rem'
        }
    }
}

const IndicatorsContainer = props => {
  return (
    <Indicator/>
  )
}

export default field => {

    return(
        <Select 
            blurInputOnSelect={false}
            className='w-100'
            closeMenuOnSelect={field.isMultiple ? false : true}
            components={{ IndicatorsContainer }}
            isMulti={field.isMultiple}
            name={field.input.name}
            noOptionsMessage={() => 'Ningún resultado'}
            onBlurResetsInput={false}
            onChange={value => {
                field.input.onChange(value)
                field.forzeSubmit(value)
            }}
            onCloseResetsInput={false}
            onFocus={field.input.onFocus}
            options={field.options}
            placeholder={field.placeholder}
            styles={colourStyles}
            value={field.input.value}
        />
    )
}