import styled from 'styled-components'
import { Alert } from 'reactstrap'

export const FixedAlert = styled(Alert)`
    background-color: white;
    border: 1px solid ${props => props.theme.color.secondary};
    border-radius: 3rem;
    color: ${props => props.theme.color.secondary};
    font-size: 0.8rem;
    margin: 0 auto;
    position: fixed!important;
    top: calc(${props => props.theme.height_porcentage.navbar}vh + 1vh);
    width: 40%;
    z-index: 100;
    button {
        padding: 0.6rem 1.25rem!important;
    }
    @media (max-width: 500px){
        font-size: 0.6rem;
        width: auto;
        button {
            font-size: 1.2rem;
        }
    }

`

export const AbsoluteAlert = styled(Alert)`
    background-color: white;
    border: 1px solid ${props => props.theme.color.secondary};
    border-radius: 3rem;
    color: ${props => props.theme.color.secondary};
    font-size: 0.8rem;
    margin: 0 auto;
    position: absolute!important;
    top: 25%;
    z-index: 100;
    button {
        padding: 0.6rem 1.25rem!important;
    }
    @media (max-width: 500px){
        font-size: 0.6rem;
        width: auto;
        button {
            font-size: 1.2rem;
        }
    }

`

export const StyledHomeMobileAlert = styled(Alert)`
    background-color: ${props => props.theme.color.alert_background};
    border: none;
    color: ${ props => props.theme.color.alert_font};
    font-size: 0.6rem;
    margin-bottom: 0;
`