import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { COUNTRIES_WITH_ZONES, MAP } from 'constants/index'

export function getZoneCodes(state) {
  return {
    country_code: state.features[0].properties['GID_0'],
    zone_code: state.features[0].properties['GID_1']
  }
}

export function getCountriesWithZones(countries) {
  const filteredCountries = filter(countries, (country) => {
    return includes(COUNTRIES_WITH_ZONES, country.id)
  })

  return map(filteredCountries, (country) => {
    return country.id
  })
}

export function mixCountries(DataGeoJson, DataFirebase) {
  DataGeoJson.features.forEach((country) => {
    const country_data = filter(DataFirebase, (data_country) => {
      return data_country.code === country.properties.GID_0
    })
    country.properties[MAP.ABORTION_LEVEL] = country_data[0][MAP.ABORTION_LEVEL] || Math.floor(Math.random() * 7)
  })
  return DataGeoJson
}

export function mixCountriesLabels(DataGeoJson, DataFirebase) {
  DataGeoJson.features.forEach((country) => {
    const country_data = filter(DataFirebase, (data_country) => {
      return data_country.code === country.properties.GID_0
    })
    country.properties.NAME_0 = country_data[0].name
  })
  return DataGeoJson
}

export function mixZonesOfCountries(DataGeoJson, DataFirebase) {
  DataGeoJson.features.forEach((state) => {
    const state_data = filter(DataFirebase, (data_state) => {
      return data_state.code === state.properties.GID_1
    })
    state.properties[MAP.ABORTION_LEVEL] = state_data[0][MAP.ABORTION_LEVEL] || Math.floor(Math.random() * 7)
  })
  return DataGeoJson
}

export function mixZonesOfCountriesLabels(DataGeoJson, DataFirebase) {
  DataGeoJson.features.forEach((state) => {
    const state_data = filter(DataFirebase, (data_state) => {
      return data_state.code === state.properties.GID_1
    })
    state.properties.NAME_1 = state_data[0].name
  })
  return DataGeoJson
}

export function getAbortionLevelOfCountries(DataGeoJson, DataFirebase) {
  // Por cada capa (tipo objeto) del SVG JSON, se agrega una propiedad para el nivel de aborto
  // correspondiente a lo que hay en base de datos Firestore
  DataGeoJson.locations.forEach((layer) => {
    const country_data = filter(DataFirebase, (data_country) => {
      return data_country.code === layer.id
    })
    if (country_data.length > 0) layer.causal = country_data[0].causal_level
  })
  return DataGeoJson
}

export function getSearchOptions(countries) {
  const options = map(countries, (country) => {
    return { label: country.name, value: country.code }
  })

  return orderBy(options, ['label'], ['asc'])
}

export function getAbortionLevelOfZones(DataGeoJson, DataFirebase) {
  // Por cada capa (tipo objeto) del SVG JSON, se agrega una propiedad para el nivel de aborto
  // correspondiente a lo que hay en base de datos Firestore
  DataGeoJson.locations.forEach((layer) => {
    const zone_data = filter(DataFirebase, (data_zone) => {
      return data_zone.code === layer.id
    })
    // console.log('zone_data-->', zone_data)
    if (zone_data.length > 0) layer.causal = zone_data[0].causal_level
  })
  return DataGeoJson
}
