import React from 'react'
import { StyledCloseModal, ContainerCloseModal } from './styled'
import CloseSVG from 'assets/close_modal_icon.svg'

export default props => {
    return (
        <ContainerCloseModal className='bg-white p-2'>
            <StyledCloseModal src={CloseSVG}/>
        </ContainerCloseModal>
    )
}