import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Logo from 'components/atoms/images/logo_navbar'
import SearchBar from 'components/organisms/search_input/with_icon_button_desktop'
import { Container, LogoContainer } from './styled'
import { NAVBAR_TYPE } from 'constants/index'
import * as routes from 'constants/routes'

class MainDesktopNavbar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            countryCode: ''
        }
        this.onCountrySearch = this.onCountrySearch.bind(this)
        this.onZoneSearch = this.onZoneSearch.bind(this)
    }

    componentDidMount(){
        let country_code
        if(this.props.location.pathname === routes.MEXICO_MAP)
            country_code = 'MEX'
        else
            country_code = this.props.match.params.country_code

        this.setState({ country_code })
    }

    onCountrySearch(country_code){
        if(country_code === 'MEX')
                this.props.history.push(routes.MEXICO_MAP)
        else{
            const route = routes.COUNTRY.replace(':country_code', country_code)
            this.props.history.push(route)
        }
            
    }

    onZoneSearch(zone_code){
        const route = routes.ZONE.replace(':country_code', this.state.country_code).replace(':zone_code', zone_code)
                this.props.history.push(route)
    }

    render(){
        return(
            <Container className='row no-gutters'>
                <div className='col-3 pl-4'>
                    <LogoContainer className='d-flex align-items-center'>
                        <Logo/>
                    </LogoContainer>
                </div>
                <div className='col-9 pr-4'>
                    <SearchBar 
                        options={this.props.type === NAVBAR_TYPE.countries ? this.props.countries_search_options : this.props.zones_search_options}
                        onSearchSubmit={this.props.type === NAVBAR_TYPE.countries ? this.onCountrySearch : this.onZoneSearch}
                        type={this.props.type}
                    />
                </div>
            </Container>
        )
    }
}

function mapStateToProps({ map }){
    const { countries_search_options, zones_search_options } = map
    
    return { countries_search_options, zones_search_options }
}

MainDesktopNavbar = connect(
    mapStateToProps,
    {}
)(MainDesktopNavbar)

export default withRouter(MainDesktopNavbar)