import styled from 'styled-components'
import PageLoaderGifHD from 'assets/loader.gif'
import PageLoaderGifResponsive from 'assets/loader_responsive.gif'

export const PageLoaderDiv = styled.div`
    background: rgba(255,255,255,1) url(${ props => props.hd ? PageLoaderGifHD : PageLoaderGifResponsive }) no-repeat center center;
    background-size: contain;
    display: ${ props => props.active ? 'initial' : 'none' };
    height: 100vh;
    left: 0;
    padding: 4rem 1rem;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100vw;
    z-index: 10000;
`

export const StyledPrimaryH3 = styled.h3`
    color: ${props => props.theme.color.primary}
`
export const StyledPrimaryH5 = styled.h5`
    color: ${props => props.theme.color.primary}
`

export const StyledSecondaryH5 = styled.h5`
    color: ${props => props.theme.color.secondary}
`

export const StyledSecondaryH6 = styled.h6`
    color: ${props => props.theme.color.secondary}
`