import styled from 'styled-components'

export const FormContainer = styled.div`
    height: 100%;
`

export const StyledForm = styled.form`
    z-index: 1;
`

export const DivShadow = styled.div`
    box-shadow: 24px 24px 39px -2px rgba(0,0,0,0.4);
`

export const SearchContainer = styled.div`
    height: 45.45%;
    min-height: 45.45%;
    max-height: 45.45%;
    position: relative;
`