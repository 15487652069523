import styled from 'styled-components'

export const Container = styled.div`
    height: 4rem;
`

const Tab = styled.div`
    background-color: ${props => props.selected ? props.theme.color.active_tab_background : props.theme.color.inactive_tab_background};
    color: ${props => props.selected ? 'white' : props.theme.color.label};
    cursor: ${props => props.selected ? 'default' : 'pointer'};
    &:hover {
        opacity: ${props => props.selected ? 'default' : '0.7'};
    }
`

export const LeftRounded = styled(Tab)`
    border-top-left-radius: 3.5rem;
    border-bottom-left-radius: 3.5rem;
    height: 100%;
`

export const RightRounded = styled(Tab)`
    border-top-right-radius: 3.5rem;
    border-bottom-right-radius: 3.5rem;
    height: 100%;
`