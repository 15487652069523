import React from 'react'
import { StyledBodyText } from './styled'

export default props => {
    return (
        <StyledBodyText 
            className={props.className}
            color={props.color}
            hover={props.hover}>
            {props.children}
        </StyledBodyText>
    )
}