export const buttons = {
	view: {
		en: 'View',
		es: 'Ver',
		fr: 'Regarder',
		pt: 'Ver'
	},
	expand: {
		en: 'Expand information',
		es: 'Expandir información',
		fr: 'Afficher les informations',
		pt: 'Exibir informações'
	},
	send: {
		en: 'Send',
		es: 'Enviar',
		fr: '',
		pt: ''
	}
}

export const map_legend = {
	allowed: 'Menos penalizado',
	not_allowed: 'Más penalizado'
}

export const map = {
	no_info_alert: 'Estamos trabajando en este contenido.'
}

export const navbar = {
	countries: {
		search_placeholder: 'Selecciona el país que deseas buscar'
	},
	zones: {
		search_placeholder: 'Selecciona el estado que deseas buscar'
	}
}

export const footer = {
	who_we_are: '¿Quiénes somos?',
	privacy: 'Aviso de privacidad',
	contact: 'Contáctanos',
	copyright: `Ipas LAC, ${new Date().getFullYear()}. Derechos Reservados.`,
	description:
		'Toda la información proporcionada en este sitio web, incluyendo la contenida en textos, videos y audios, tiene únicamente fines informativos y está basada en la evidencia clínica más reciente disponible y el marco jurídico vigente de los países, al momento de su publicación. Por lo que no pretende sustituir la asesoría de profesionales de la salud o jurídica que posiblemente requiera la persona que la consulta.'
}

export const mobile_alert = {
	title: 'Tienes dos opciones para ver la información de cada país:',
	title_mexico: 'Tienes dos opciones para ver la información de cada estado:',
	option_1: '1) Usa el buscador',
	option_2: '2) Toca el mapa'
}

export const country_header = {
	subtitle: {
		en: 'Access to abortion in',
		es: 'Acceso al aborto en',
		fr: "Accès à l'avortement en",
		pt: 'Acesso ao aborto em'
	}
}

export const tabs = {
	allowed: {
		en: "It's allowed?",
		es: '¿Está permitido?',
		fr: "C'est autorisé?",
		pt: 'Está permitido?'
	},
	access: {
		en: 'How do I access?',
		es: '¿Cómo accedo?',
		fr: 'Comment y accéder?',
		pt: 'Como faço para acessar?'
	},
	need_company: {
		en: 'Shall we accompany you?',
		es: 'Organizaciones que pueden ayudarte',
		fr: 'Devons-nous vous accompagner?',
		pt: 'Vamos acompanhá-lo?'
	}
}

export const show_region = {
	titles: {
		allowed: {
			en: 'Is pregnancy legal termination allowed in :region_name?',
			es: '¿Está permitido interrumpir el embarazo en :region_name?',
			fr: 'L’interruption volontaire de grossesse est-elle autorisée à :region_name?',
			pt: 'É permitido interromper a gravidez no :region_name?'
		},
		access: {
			en: 'How can I access abortion services in :region_name?',
			es: '¿Cómo accedo a un servicio de aborto en :region_name?',
			fr: 'Comment puis-je accéder aux services d’interruption volontaire de grossesse à :region_name?',
			pt: 'Como posso acessar o serviço no :region_name?'
		},
		help: {
			en: 'Do you need help?',
			es: 'Organizaciones que pueden ayudarte',
			fr: 'T’as besoin d’aide',
			pt: 'Precisa de ajuda?'
		}
	},
	descriptions: {
		help: {
			en: 'Here is a list of organizations that can guide you to identify what your options are in the country where you live. These are organizations with years of experience in accompanying women and defending their rights.',
			es: 'Aquí te presentamos una lista de organizaciones que pueden orientarte para identificar cuáles son tus opciones en el país donde vives. Se trata de organizaciones con años de experiencia en el acompañamiento de las mujeres y la defensa de sus derechos.',
			fr: "Voici une liste d'organisations qui peuvent vous guider pour identifier quelles sont vos options dans le pays où vous vivez. Ce sont des organisations avec des années d'expérience dans l'accompagnement des femmes et la défense de leurs droits.",
			pt: 'Aqui está uma lista de organizações que podem orientá-lo a identificar quais são suas opções no país onde você mora. São organizações com anos de experiência no acompanhamento de mulheres e na defesa de seus direitos.'
		}
	},
	no_info: 'Estamos trabajando en este contenido.',
	no_causals: 'Estamos trabajando es estas causales.',
	references: {
		en: 'Reference documents',
		es: 'Documentos de referencia',
		fr: 'Documents de référence',
		pt: 'Documentos de referência'
	}
}

export const contact_view = {
	title: {
		es: '¿Necesitas contactarnos?'
	},
	form: {
		country: {
			es: 'De qué país requieres información*'
		},
		email: {
			es: 'Escribe tu correo electrónico*'
		},
		message: {
			es: 'Ingresa tu mensaje, procura ser lo más detallada posible*'
		},
		error: {
			es: 'Hubo un problema, no se envió el mensaje, inténtalo de nuevo'
		},
		success: {
			title: {
				es: 'Mensaje enviado'
			},
			subtitle: {
				es: 'Gracias por contactarnos, en breve recibirás nuestra respuesta.'
			}
		}
	},
	alternative: {
		es: 'También puedes escribirnos a:'
	}
}

export const privacy_view = {
	title: {
		es: 'Aviso de privacidad'
	},
	body: {
		es: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In elementum feugiat pellentesque. Nam a ligula nulla. Donec sit amet lorem at nibh egestas consectetur. Maecenas ligula turpis, fermentum at pretium at, vulputate ac massa. Sed sed imperdiet urna. Aenean sit amet leo a est pulvinar pretium eget eu purus. Phasellus non turpis lorem. Donec feugiat maximus purus ut vehicula. Duis ornare ipsum a ante rhoncus, non vulputate augue ultricies. Sed eget faucibus ex. Praesent sit amet eleifend diam, quis aliquam justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur et ex sit amet mauris volutpat placerat non quis orci. Quisque a nunc sed urna mollis convallis vitae vel nisl.

        Nulla lobortis nisl at turpis porttitor dapibus. Suspendisse tincidunt placerat condimentum. Ut non maximus lorem. In sed purus tincidunt, viverra turpis nec, blandit elit. Integer fringilla, tellus eget maximus dignissim, nisl odio ornare velit, sit amet pulvinar lorem turpis eget orci. Praesent magna lacus, placerat sed nulla ac, pretium egestas odio. Quisque blandit nibh id ex viverra mollis. Aliquam ac lorem suscipit, tincidunt felis id, efficitur ligula. Nullam tempus, libero a sollicitudin commodo, eros erat semper massa, ac congue dolor turpis quis lorem. Sed consequat nisi nec aliquet commodo. Nullam dapibus id tellus ac condimentum. Curabitur neque lorem, iaculis vel urna vitae, tristique placerat risus.
        
        Vivamus in tristique justo. Vestibulum viverra metus enim, ut fermentum urna placerat vitae. Proin suscipit mauris felis, ac lacinia eros molestie sollicitudin. Quisque ac enim tortor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis luctus lacus id lobortis scelerisque. Proin aliquet, enim id ultrices molestie, libero eros vulputate ipsum, a ullamcorper felis nisi vulputate risus. Pellentesque sit amet leo eu felis posuere vestibulum congue a justo. Sed luctus pulvinar diam, quis maximus nisl elementum vel. Nam pharetra lacus dui, sagittis mattis nunc dignissim eget. Nullam tincidunt urna quis sodales pharetra. Donec condimentum lorem sed ipsum mattis varius.
        
        Cras sodales mauris vel urna viverra, ut lacinia mi malesuada. Nulla facilisi. Duis sed tortor condimentum, tempor diam vitae, posuere quam. Nullam laoreet in mi tristique hendrerit. Nunc lacinia elit dictum, venenatis sem ac, pulvinar lectus. Praesent id arcu eu erat maximus maximus. In id ante mauris.
        
        Praesent lobortis pharetra turpis, in luctus ex iaculis id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultrices sit amet tellus pharetra placerat. Nam sollicitudin diam ipsum, nec mattis enim mollis cursus. Nam tortor tellus, fringilla et nisl et, interdum iaculis urna. Etiam sit amet laoreet nisl, nec dapibus arcu. Quisque vitae turpis lectus. Curabitur et tellus diam. Pellentesque in erat vel dui blandit tincidunt. Phasellus id ex massa. Phasellus eu nunc eget massa blandit hendrerit. Sed id elit sapien. Phasellus sit amet accumsan nunc. Nam bibendum lacus nec ligula faucibus, sit amet accumsan dui iaculis.`
	}
}

export const us_view = {
	title: {
		es: 'Sobre nosotros'
	},
	body: {
		es: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In elementum feugiat pellentesque. Nam a ligula nulla. Donec sit amet lorem at nibh egestas consectetur. Maecenas ligula turpis, fermentum at pretium at, vulputate ac massa. Sed sed imperdiet urna. Aenean sit amet leo a est pulvinar pretium eget eu purus. Phasellus non turpis lorem. Donec feugiat maximus purus ut vehicula. Duis ornare ipsum a ante rhoncus, non vulputate augue ultricies. Sed eget faucibus ex. Praesent sit amet eleifend diam, quis aliquam justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Curabitur et ex sit amet mauris volutpat placerat non quis orci. Quisque a nunc sed urna mollis convallis vitae vel nisl.

        Nulla lobortis nisl at turpis porttitor dapibus. Suspendisse tincidunt placerat condimentum. Ut non maximus lorem. In sed purus tincidunt, viverra turpis nec, blandit elit. Integer fringilla, tellus eget maximus dignissim, nisl odio ornare velit, sit amet pulvinar lorem turpis eget orci. Praesent magna lacus, placerat sed nulla ac, pretium egestas odio. Quisque blandit nibh id ex viverra mollis. Aliquam ac lorem suscipit, tincidunt felis id, efficitur ligula. Nullam tempus, libero a sollicitudin commodo, eros erat semper massa, ac congue dolor turpis quis lorem. Sed consequat nisi nec aliquet commodo. Nullam dapibus id tellus ac condimentum. Curabitur neque lorem, iaculis vel urna vitae, tristique placerat risus.
        
        Vivamus in tristique justo. Vestibulum viverra metus enim, ut fermentum urna placerat vitae. Proin suscipit mauris felis, ac lacinia eros molestie sollicitudin. Quisque ac enim tortor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis luctus lacus id lobortis scelerisque. Proin aliquet, enim id ultrices molestie, libero eros vulputate ipsum, a ullamcorper felis nisi vulputate risus. Pellentesque sit amet leo eu felis posuere vestibulum congue a justo. Sed luctus pulvinar diam, quis maximus nisl elementum vel. Nam pharetra lacus dui, sagittis mattis nunc dignissim eget. Nullam tincidunt urna quis sodales pharetra. Donec condimentum lorem sed ipsum mattis varius.
        
        Cras sodales mauris vel urna viverra, ut lacinia mi malesuada. Nulla facilisi. Duis sed tortor condimentum, tempor diam vitae, posuere quam. Nullam laoreet in mi tristique hendrerit. Nunc lacinia elit dictum, venenatis sem ac, pulvinar lectus. Praesent id arcu eu erat maximus maximus. In id ante mauris.
        
        Praesent lobortis pharetra turpis, in luctus ex iaculis id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultrices sit amet tellus pharetra placerat. Nam sollicitudin diam ipsum, nec mattis enim mollis cursus. Nam tortor tellus, fringilla et nisl et, interdum iaculis urna. Etiam sit amet laoreet nisl, nec dapibus arcu. Quisque vitae turpis lectus. Curabitur et tellus diam. Pellentesque in erat vel dui blandit tincidunt. Phasellus id ex massa. Phasellus eu nunc eget massa blandit hendrerit. Sed id elit sapien. Phasellus sit amet accumsan nunc. Nam bibendum lacus nec ligula faucibus, sit amet accumsan dui iaculis.`
	}
}
