import styled from 'styled-components'

export const Container = styled.div`
    background-color: ${({ theme }) => theme.color.secondary};
    cursor: pointer;
    height: ${({ theme }) => theme.height_porcentage.sidebar}vh;
    @media (max-width: 767px){
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;
        height: 2rem;
        padding: 1.2rem 1rem;
    }
    @media (min-width: 768px){
        position: ${({ isOpen }) => isOpen ? 'inherit' : 'absolute'};
        right: 0;
        transition-delay 0s, 0.3s;
        transition-duration 0.3s, 0s;
        transition-timing-function ease, ease;
        transform: translateX(${({ isOpen }) => isOpen ? '0' : '100%'});
    }
`